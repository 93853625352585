import Moment from 'moment';
import _ from 'lodash';
import State from 'lib/state';

export class DateFormatValueConverter {
  format = '';
  constructor() {
    this.lng = State.get('me.language', 'en');
  }

  toView(value, format) {
    this.lng = State.get('me.language', 'en');

    if (!value) {
      return '';
    }

    this.format = format
      ? format
      : this.lng == 'vi'
      ? 'DD/MM/YYYY'
      : 'MM/DD/YYYY';

    Moment.locale(this.lng);

    if (typeof value == 'string') {
      return value;
    }

    return Moment.utc(value)
      .utcOffset(State.get('me.time_zone.value', 7))
      .format(this.format);
  }

  fromView(value, format) {
    this.lng = State.get('me.language', 'en');

    if (!value) {
      return '';
    }

    this.format = format
      ? format
      : this.lng == 'vi'
      ? 'DD/MM/YYYY'
      : 'MM/DD/YYYY';

    var mytimezone = Moment()
      .utcOffset(State.get('me.time_zone.value', 7))
      .format('ZZ');
    return +Moment(`${value} ${mytimezone}`, `${this.format} ZZ`).utc();
    // return +moment.utc(value, this.format);
  }
}
