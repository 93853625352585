import {customElement, inject, bindable, bindingMode} from 'aurelia-framework';
import Moment from 'moment';

import * as Util from 'lib/util';

@customElement('mde-switch')
@inject(Element)
export class MdeSwitch {
  @bindable({defaultBindingMode: bindingMode.oneWay}) type = 'number';
  @bindable({defaultBindingMode: bindingMode.twoWay}) value = 0;
  @bindable({defaultBindingMode: bindingMode.twoWay}) checked = false;
  @bindable mdeId = `mde-switch-id-${
    +Moment.utc() + parseInt(Math.random() * 10000)
  }`;

  constructor(element) {
    this.element = element;
  }

  setValue(value) {
    if (this.type === 'boolean') {
      this.value = value ? true : false;
    } else {
      this.value = value ? 1 : 0;
    }
  }

  valueChanged() {
    if (this.myChecked) {
      this.myChecked = false;
      return;
    }
    this.checked = this.value == 1 ? true : false;
  }

  change(event) {
    event.stopPropagation();
  }

  checkedChanged() {
    this.myChecked = true;
    this.setValue(this.checked);
    if (this.valueTimeout) {
      clearTimeout(this.valueTimeout);
    }
    this.valueTimeout = setTimeout(() => {
      Util.fireEvent(this.element, this.value);
    }, 250);
  }

  attached() {
    if (!this.value && this.value !== 0) {
      this.value = 0;
    }
  }
}
