export function configure(config) {
  config.globalResources([
    './value-converters/date-format',
    './value-converters/datetime-format',
    './value-converters/translate',
    './value-converters/secure-html',
    './custom-elements/mde-message',
    './custom-elements/mde-popup',
    './custom-elements/mde-table-v2',
    './custom-elements/datetime-picker',
    './custom-elements/mde-dropdown',
    './custom-elements/mde-input',
    './custom-elements/mde-switch',
    './custom-elements/mde-slider',
    './custom-elements/mde-tags-event',
    './custom-elements/mde-dropdown-event',
    './custom-elements/mde-datepicker-event',
    './custom-elements/select-country/index',
    './custom-elements/select-language/index',
    './custom-elements/intl-tel-input/index',
    './custom-elements/recaptcha',
    './custom-elements/mde-background',
  ]);
}
