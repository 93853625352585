import {customElement, inject, bindable, bindingMode} from 'aurelia-framework';
import * as Util from 'lib/util';
import * as Locale from 'lib/locale';
import * as CommonService from 'services/common.v2';

@customElement('mde-dropdown')
@inject(Element)
export class MdeDropdown {
  // init variables
  @bindable({
    defaultBindingMode: bindingMode.twoWay,
  })
  chooser = ''; // The default selected value
  @bindable options = []; // The label/option values
  @bindable url;
  @bindable mdeId = `dropdown-${
    Date.now() + (Math.random() * Date.now()).toFixed(0)
  }`;
  @bindable mapping = {
    id: '_id',
    text: 'name',
  };
  @bindable itemMapping = {
    url: '',
    text: 'name',
  };
  @bindable placeholder = '--';
  @bindable mdeStyle = '';
  @bindable mdeClass = '';
  @bindable isReset = true;
  @bindable isDisabled = false;
  @bindable mdeConfigs = {};

  data = [];
  selectedItem;
  wrapper = false;
  //    html= "";
  constructor(element) {
    this.element = element;
  }

  attached() {
    this.WidthJS();
  }

  hasFocus() {
    this.wrapper = true;
    this.onOpen();
  }

  hasBlur() {
    if (this.wrapperTimeout) {
      clearTimeout(this.wrapperTimeout);
    }
    this.wrapperTimeout = setTimeout(() => {
      this.wrapper = false;
    }, 250);
    this.WidthJS();
  }

  chooserChanged(newValue) {
    if (this.isAddSelectedItem) {
      this.isAddSelectedItem = false;
      return;
    }
    if (this.chooserTimeout) {
      clearTimeout(this.chooserTimeout);
    }

    this.chooserTimeout = setTimeout(async () => {
      if (!this.chooser && this.chooser != 0) {
        Util.fireEvent(this.element, {});
        this.selectedItem = null;
        return;
      }
      await this.onOpen();
      this.getStaticDataByKeys(this.chooser);
      // console.log("getStaticDataByKeys: ", this.getStaticDataByKeys(this.chooser));
    }, 250);
  }

  addSelectedItem(item, index) {
    if (this.isDisabled) {
      return;
    }
    this.chooser = this.getId(item);
    this.isAddSelectedItem = true;
    this.selectedItem = item;
    this.element.querySelector('.mde-cP').blur();
    Util.fireEvent(this.element, item);
    // console.log("chooser: ", this.chooser, "--isAddSelectedItem: ", this.isAddSelectedItem, "--selectedItem: ", this.selectedItem, "--mde-cP: ", this.element.querySelector(".mde-cP"), "--fireEvent: ", Util.fireEvent(this.element, item));
  }

  async onOpen() {
    if (!this.url) {
      this.data = this.options || [];
      return;
    }

    var response = await CommonService.GET(this.url);
    let {data: result, error} = response;
    if (!data || error) {
      return;
    }
    this.data = (this.options || []).concat(result || []);
  }

  resetSearchKey() {
    this.chooser = '';
    Util.fireEvent(this.element, {});
    this.selectedItem = null;
  }

  getId(item) {
    let id = item[(this.mapping || {}).id];
    if (!id && id != 0) {
      return (item.value || item.id || item._id || '').toString();
    }
    return id.toString();
  }

  getText(item) {
    if (!item) {
      return '';
    }

    var text = item[(this.mapping || {}).text] || item.text || item.name || '';
    if (item.is_translate && text) {
      return Locale.translate(text);
    }
    return text;
  }

  getStaticDataByKeys(key) {
    if (!key || !this.data || this.data.length == 0) {
      Util.fireEvent(this.element, {});
      this.selectedItem = null;
      return;
    }
    var myOption =
      this.data.filter((option) => {
        return this.getId(option) == key;
      })[0] || null;
    Util.fireEvent(this.element, myOption);
    this.selectedItem = myOption;
    // console.log("selectedItem:", this.selectedItem);
  }

  WidthJS() {
    let findName = '.mde-drop-down-wrapper';
    let findNameInput = findName + ' input';
    if (
      !this.element.querySelector('.mde-add-maxWidth') ||
      !this.element.querySelector('.mde-getWidth') ||
      !this.element.querySelector(findName)
    ) {
      return;
    }
    var that = this.element;
    var timeOut = 250;
    if (!this.element.querySelector('.mde-results-content')) {
      timeOut = 600;
    } else {
      let getItemFirst = this.element.querySelector(
        '.mde-results-content'
      ).children;
      if (getItemFirst.length == 0) {
        timeOut = 600;
      }
    }

    setTimeout(function () {
      let getItemSecond = that.querySelector(findNameInput).value;
      if (getItemSecond.split(' ', 3).length < 2) {
        getItemSecond = that.querySelector(findNameInput).placeholder;
      }
      that.querySelector('.mde-getWidth .mde-getWidthContent').innerHTML =
        getItemSecond;
      var getWidth = that.querySelector(
        '.mde-getWidth .mde-getWidthContent'
      ).offsetWidth;
      that.querySelector(findName).style.width = 100 + '%';
      if (getWidth < 100) {
        that.querySelector(findName).style.maxWidth = 150 + 'px';
      } else {
        that.querySelector(findName).style.maxWidth = getWidth + 60 + 'px';
      }
    }, timeOut);
  }
}
