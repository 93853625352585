import _ from 'lodash';
import Moment from 'moment';
import State from 'lib/state';

export class DatetimeFormatValueConverter {
  format = '';
  constructor() {
    this.lng = State.get('me.language', 'en');
  }
  toView(value, is_time) {
    this.lng = State.get('me.language', 'en');
    if (!value) {
      return '';
    }
    if (typeof value == 'string') {
      value = Number(value);
    }

    if (is_time && _.isString(is_time)) {
      this.format = is_time;
    } else if (is_time) {
      this.format = this.lng == 'vi' ? 'DD/MM/YYYY H:mm' : 'MM/DD/YYYY H:mm';
    } else {
      this.format = this.lng == 'vi' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
    }
    Moment.locale(this.lng);

    if (typeof value == 'string') {
      return value;
    }
    return Moment(value)
      .utcOffset(State.get('me.time_zone.value', 7))
      .format(this.format);
  }
  fromView(value, is_time) {
    this.lng = State.get('me.language', 'en');
    if (!value) {
      return '';
    }
    if (is_time) {
      this.format = this.lng == 'vi' ? 'DD/MM/YYYY H:mm' : 'MM/DD/YYYY H:mm';
    } else {
      this.format = this.lng == 'vi' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
    }
    var mytimezone = Moment()
      .utcOffset(State.get('me.time_zone.value', 7))
      .format('ZZ');
    return +Moment(`${value} ${mytimezone}`, `${this.format} ZZ`).utc();
  }
}

export class DatetimeFormatPatternValueConverter {
  format = '';
  constructor() {
    this.lng = State.get('me.language', 'en');
  }
  toView(value, format) {
    this.lng = State.get('me.language', 'en');
    if (!value) {
      return '';
    }
    if (typeof value == 'string') {
      value = Number(value);
    }
    Moment.locale(this.lng);

    if (typeof value == 'string') {
      return value;
    }
    format = format || 'MM/DD/YYYY';
    return Moment(value)
      .utcOffset(State.get('me.time_zone.value', 7) || 7)
      .format(format);
  }
}

export class TimeFromNowValueConverter {
  constructor() {
    this.lng = State.get('me.language', 'en');
  }
  toView(value) {
    Moment.locale(this.lng);
    return Moment(value)
      .utcOffset(State.get('me.time_zone.value', 7) || 7)
      .fromNow();
  }
}

export class TimeViewValueConverter {
  toView(value) {
    this.lng = State.get('me.language', 'en');
    var today = +Moment.utc(),
      yesterday = +Moment.utc().subtract(1, 'days');
    if (
      value >= +Moment(today).startOf('days') &&
      value <= +Moment(today).endOf('days')
    ) {
      return this.lng == 'vi' ? 'Hôm nay' : 'Today';
    } else if (
      value >= +Moment(yesterday).startOf('days') &&
      value <= +Moment(yesterday).endOf('days')
    ) {
      return this.lng == 'vi' ? 'Hôm qua' : 'Yesterday';
    } else {
      this.format = this.lng == 'vi' ? 'MMMM/YYYY' : 'MMMM/YYYY';
      Moment.locale(this.lng);
      return _.upperFirst(Moment(value).format(this.format));
    }
  }
}
