import Config from './config';

const sessionId = 'ss' + Math.random() * Date.now();

const isDebug =
  Config.isDebug || localStorage.getItem('debugVersion') === Config.version;

export const debug = (...params) => {
  if (isDebug || window.iziDebugging) {
    console.debug(...params);
  }
};

export const trace = (name, ...params) => {
  if (window.iziTracing === name) {
    console.group(name);
    console.trace(...params);
    console.groupEnd(name);
  }
};

window[sessionId] = debug;
