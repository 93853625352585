import {customElement, inject, bindable, bindingMode} from 'aurelia-framework';
import * as Util from 'lib/util';

const EMAIL_REGEXP =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

const parseUrl = (url) => {
  try {
    return new URL(url);
  } catch (err) {}
};

@customElement('mde-input')
@inject(Element)
export class MdeInput {
  // init variables
  @bindable({
    defaultBindingMode: bindingMode.twoWay,
  })
  value = ''; // The default selected value
  @bindable type = 'text';
  @bindable min = null;
  @bindable max = null;
  @bindable isDisabled = false;
  @bindable mdeStyle = '';
  @bindable mdeClass = '';
  @bindable placeholder = '';
  @bindable rows = '3'; // for textarea
  @bindable maxLength = '524288'; // for input

  constructor(element) {
    this.element = element;
  }

  attached() {
    if (this.type == 'textarea') {
      this.initJS();
    }
    this.WidthJS();
  }

  valueChanged() {
    if (this.valueTimeout) {
      clearTimeout(this.valueTimeout);
    }
    this.valueTimeout = setTimeout(() => {
      Util.fireEvent(this.element, this.value);
    }, 250);
  }

  onKeyChanged(event) {
    event.stopImmediatePropagation();
    this.validation();
  }

  goToUrl() {
    if (!this.value) {
      return;
    }
    window.open(this.value, '_blank');
  }

  validation() {
    // only integer
    if (this.type == 'integer') {
      if (/^\d+$/.test(this.value) === false) {
        this.value = '';
      } else {
        this.value = parseInt(this.value);
        if (this.min !== null && this.value < this.min) {
          this.value = '';
        }
        if (this.max !== null && this.value > this.max) {
          this.value = '';
        }
      }
      return;
    }
    // only float
    else if (this.type == 'float') {
      if (/^[0-9]+\.[0-9]+$/.test(this.value) === false) {
        this.value = '';
      } else {
        this.value = parseFloat(this.value);
        if (this.min !== null && this.value < this.min) {
          this.value = '';
        }
        if (this.max !== null && this.value > this.max) {
          this.value = '';
        }
      }
      return;
    } // only number
    else if (this.type == 'number') {
      if (/^-?\d*(\.\d+)?$/.test(this.value) === false) {
        this.value = '';
      } else {
        this.value = Number(this.value);
        if (this.min !== null && this.value < this.min) {
          this.value = '';
        }
        if (this.max !== null && this.value > this.max) {
          this.value = '';
        }
      }
      return;
    } else if (this.type == 'email') {
      const formatted = _.trim(_.toLower(this.value));
      if (EMAIL_REGEXP.test(formatted) === false) {
        this.value = '';
      } else {
        this.value = formatted;
      }
      return;
    }
    // url
    else if (this.type == 'url' && !parseUrl(this.value)) {
      this.value = '';
      return;
    }
  }
  //autosize
  initJS() {
    var textareaWrapper =
      this.element.getElementsByClassName('mde-autosize')[0];
    //alert(textareaWrapper);
    if (textareaWrapper !== undefined) {
      var textarea = this.element.querySelector(
        '.mde-textarea-wrapper.mde-autosize textarea'
      );
      textarea.addEventListener('keydown', autosize);
      textarea.addEventListener('click', autosize);

      function autosize(event) {
        setTimeout(function () {
          event.target.style.cssText = 'height:auto;';
          event.target.style.cssText =
            'height:' + event.target.scrollHeight + 'px';
        }, 0);
      }
    }
  }

  WidthJS() {
    let findName = '.mde-textarea-wrapper';
    let findNameInput = findName + ' input';
    if (
      !this.element.querySelector('.mde-add-maxWidth') ||
      !this.element.querySelector('.mde-getWidth') ||
      !this.element.querySelector(findName)
    ) {
      return;
    }
    var that = this.element;
    var timeOut = 250;
    if (!this.element.querySelector('.mde-results-content')) {
      timeOut = 600;
    } else {
      let getItemFirst = this.element.querySelector(
        '.mde-results-content'
      ).children;
      if (getItemFirst.length == 0) {
        timeOut = 1000;
      }
    }

    setTimeout(function () {
      let getItemSecond = that.querySelector(findNameInput).value;
      if (getItemSecond.split(' ', 3).length < 2) {
        getItemSecond = that.querySelector(findNameInput).placeholder;
      }
      that.querySelector('.mde-getWidth .mde-getWidthContent').innerHTML =
        getItemSecond;
      var getWidth = that.querySelector(
        '.mde-getWidth .mde-getWidthContent'
      ).offsetWidth;
      that.querySelector(findName).style.width = 100 + '%';
      if (getWidth < 100) {
        that.querySelector(findName).style.maxWidth = 150 + 'px';
      } else {
        that.querySelector(findName).style.maxWidth = getWidth + 60 + 'px';
      }
    }, timeOut);
  }
}
