import Numeral from 'numeral';
import State from 'lib/state';

// number format
export class NfValueConverter {
  format = '';
  constructor() {
    Numeral.register('locale', 'vi', {
      delimiters: {
        thousands: ',',
        decimal: '.',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      currency: {
        symbol: 'đ',
      },
    });
  }

  toView(value, options) {
    this.locale = State.get('me.language', 'en');

    Numeral.locale(this.locale);
    if (!options) {
      this.format = '0,0.00';
      return Numeral(value).format(this.format);
    }
    this.format = options.format || '0,0.00';

    if (options.style === 'currency') {
      this.format = options.format
        ? options.format
        : this.locale == 'en'
        ? '$0,0.00'
        : '0,0$';
    }

    return Numeral(value).format(this.format);
  }

  fromView(value, options) {
    return value;
  }
}
