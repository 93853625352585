export class TimeFormatValueConverter {
  toView(value, format) {
    if (!value) {
      return `00:00:00`;
    }
    var minutes = Math.floor(value / 60);
    value = value % 60;
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return this.pad(hours) + ':' + this.pad(minutes) + ':' + this.pad(value);
  }
  pad(num) {
    return ('0' + num).slice(-2);
  }
}
