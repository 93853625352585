import {
  customElement,
  bindable,
  bindingMode,
  inject,
  InlineViewStrategy,
  customAttribute,
} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';
import {EventAggregator} from 'aurelia-event-aggregator';
import Moment from 'moment';

import Notifier from 'lib/notifier';
import Locale from 'lib/locale';
import State from 'lib/state';

import * as Util from 'lib/util';
import * as CommonService from 'services/common.v2';

@customElement('mde-table-v2')
@inject(Element, BindingSignaler, EventAggregator)
export class MdeTableV2 {
  @bindable mdeId;
  state = {};
  data = [];
  selectedGroups = [];
  @bindable selectedRows = [];
  @bindable mdeClass = '';
  @bindable collection = [];
  @bindable resetCallback;

  //    rowDetail ={
  //        titleField: '', // field show on title row detail,
  //        type: '',//text, html, composer
  //        path: '',//for type is composer,
  //        html: '', // for type is html,
  //        detailField: '',// for type is text
  //    }

  constructor(element, signaler, event) {
    this.element = element;
    this.signaler = signaler;
    this.event = event;

    this.title_delete = Locale.translate(`translation:ticket.delete_ticket`);
    this.msg_delete = Locale.translate(`translation:common.delete.confirm`);
    // this.isLoading = false;
  }

  trigger(event) {
    event.stopImmediatePropagation();
    if (!event.detail || !event.detail.value) {
      return;
    }
    this.handling(event.detail.value);

    this.updatedAt = Date.now();
  }

  handling(table) {
    //save old state if there is changed
    this.saveState();
    // merge table config to current view-model
    Object.assign(this, table);
    this.subscribe();

    if (this.displayText) {
      this.title_delete = this.displayText.titleDelete;
      this.msg_delete = this.displayText.msgDelete;
    }
    if (this.is_reset === true) {
      // load new data
      this.reset();
      return;
    }

    // load new data
    this.onLoading();
  }

  async attached() {
    // load new data
    this.subscribe();
  }

  detached() {
    this.saveState();
    this.unsubscribe();
  }

  subscribe() {
    if (!this.config || !this.config.realtime || this.myEvent) {
      return;
    }
    this.myEvent = this.event.subscribe(this.config.realtime, (payload) => {
      if ((payload.array_ids || []).indexOf(this.config._id) != -1) {
        this.isOutDated = true;
      }
    });
  }

  unsubscribe() {
    if (!this.myEvent) {
      return;
    }
    this.myEvent.dispose();
  }

  async onLoading() {
    //stop loading
    this.isLoading = true;
    var state = this.stateId ? State.get(`mde-table-${this.stateId}`) : {};
    if (!this.url) {
      this.processResult(this.collection || []);
      return;
    }
    // restore selected rows if any
    this.selectedRows = this.state.selectedRows || [];
    this.selectedGroups = this.state.selectedGroups || [];
    this.isOutDated = false;

    var configLimit = State.get('pagingLimit');
    let limit = this.state.limit || configLimit;
    if (limit < configLimit) {
      limit = configLimit;
    }
    var myUrl = this.parseUrl({
      iziLimit: limit,
    });

    var result = await this.fetch(myUrl);
    this.processResult(
      (this.collection || []).concat(
        this.config.dataField ? result[this.config.dataField] : result || []
      )
    );

    this.loadTotalCount(result);
    // scroll to
    this.scrollTo();
  }

  saveState(id = this.stateId) {
    // init loading, ignore
    if (!id) {
      return;
    }
    this.state = {
      scrollTop: State.get('iziScrollTop'),
      limit: this.currentLimit || 0,
      selectedRows: this.selectedRows,
      selectedGroups: this.selectedGroups,
    };
    State.set(`mde-table-${id}`, this.state);
  }

  async fetch(url) {
    this.loading = true;
    let result;
    if (this.config && this.config.method == 'POST') {
      result = await CommonService.FETCH(url, {
        data: this.config.data,
        method: this.config.method,
      });
    } else {
      result = await CommonService.GET(url);
    }

    this.loading = false;
    if (result.error) {
      console.error(result.error);
      this.data.splice(0);

      return {
        data: [],
      };
    } else if (result && this.config.resultField) {
      result = result[this.config.resultField];
    }
    return result;
  }

  async loadMore() {
    // get load more data from last record in the list
    let lastGroup = this.data[this.data.length - 1] || {
        data: [],
      },
      lastRow = lastGroup.data[lastGroup.data.length - 1] || {};
    if (this.config.isSkipNumber) {
      lastRow.lengthData = this.currentLimit;
    }
    // assign default limit
    lastRow.iziLimit = State.get('pagingLimit');

    var myUrl = this.parseUrl(lastRow);
    var result = await this.fetch(myUrl);

    this.isLoading = false;

    this.processResult(
      this.config.dataField ? result[this.config.dataField] : result,
      true
    );
  }

  async reset() {
    this.isLoading = true;
    this.state = {};
    this.selectedGroups = [];
    this.selectedRows = [];
    this.isOutDated = false;

    if (this.resetCallback) {
      this.resetCallback(true);
      return;
    }

    if (!this.url) {
      this.processResult(this.collection || []);
      return;
    }

    let limit = State.get('pagingLimit');
    var myUrl = this.parseUrl({
      iziLimit: limit,
    });

    var result = await this.fetch(myUrl);
    this.processResult(
      (this.collection || []).concat(
        this.config.dataField ? result[this.config.dataField] : result || []
      )
    );
    this.isLoading = false;
    // load total count
    this.loadTotalCount(result);
  }

  async loadTotalCount(data) {
    let totalUrl = this.config.totalUrl;
    if (!totalUrl) {
      if (data && this.config.counterField) {
        var total = 0;
        var result = data[this.config.counterField];
        if (typeof result == 'number') {
          total = result;
        }
        this.total = total;
        Util.fireEvent(this.element, {
          total: total,
        });
        return;
      } else {
        Util.fireEvent(this.element, {
          total: this.currentLimit,
        });
        return;
      }
    }
    var result = await this.fetch(totalUrl);
    if (result && this.config.totalField) {
      result = result[this.config.totalField];
    }
    var total = 0;
    if (typeof result == 'number') {
      total = result;
    }
    this.total = total;
    Util.fireEvent(this.element, {
      total: total,
    });
  }

  async deleteSelected(isConfirmed) {
    if (
      !this.selectedRows ||
      !this.selectedRows.length ||
      !this.checkable.delete_url
    ) {
      return;
    }

    if (isConfirmed) {
      this.showPopup = true;
      return;
    }
    this.showPopup = false;

    // parse ids to uri style
    let ids = '',
      symbol = '';
    this.selectedRows.forEach((id) => {
      ids += symbol + 'ids[]=' + id;
      symbol = '&';
    });
    // parse delete url
    let sign = this.checkable.delete_url.indexOf('?') !== -1 ? '&' : '?',
      myUrl = this.checkable.delete_url + sign + ids;

    var result = await CommonService.DELETE(myUrl, {});

    if (result.error) {
      let msg = 'common.delete.failed';
      if (Array.isArray(result.error)) {
        msg =
          Locale.translate(`message:common.delete.failed`) +
          '<br/>' +
          result.error.join(',');
      }
      Notifier.error(msg);
      return;
    }

    // reload new data
    setTimeout(() => {
      Notifier.success('common.delete.success');
      this.reset();
    }, 2000);
  }

  rowClass(row) {
    let classes = '',
      highlighted = row[this.config.highlightedField] || row['upd_time'];

    // if new record in less than 5 minutes
    if (
      !isNaN(highlighted) &&
      Moment.utc().diff(Moment.utc(highlighted), 'minutes') <= 5
    ) {
      classes = 'mde-line-left';
    }

    let isChecked = this.selectedRows.filter(
      (id) => id == row[this.checkable.id]
    )[0];
    if (isChecked) {
      classes += ' mde-selected';
    }
    return classes;
  }

  checkedAll(selectedRows, event) {
    event.stopPropagation();
    if (!selectedRows) {
      return false;
    }
    selectedRows.forEach((row) => {
      let newId = row[this.checkable.id];
      if (event.target.checked) {
        if (this.selectedRows.indexOf(newId) === -1) {
          this.selectedRows.push(newId);
        }
      } else {
        let index = this.selectedRows.indexOf(newId);
        if (index !== -1) {
          this.selectedRows.splice(index, 1);
        }
      }
    });
    this.signaler.signal('rowClass');
    return false;
  }

  checkedMe(group, event) {
    var ids = group.data.map((item) => item[this.checkable.id]);
    var intersects = _.intersection(ids, this.selectedRows);
    if (
      intersects.length == ids.length &&
      this.selectedGroups.indexOf(group.group) == -1
    ) {
      this.selectedGroups.push(group.group);
    } else {
      let index = this.selectedGroups.indexOf(group.group);
      if (index !== -1) {
        this.selectedGroups.splice(index, 1);
      }
    }
    event.stopPropagation();
    this.signaler.signal('rowClass');
    return false;
  }

  rendering(value) {
    return new InlineViewStrategy(`<template>${value}</template>`);
  }

  parseUrl(data) {
    let sign = this.url.indexOf('?') !== -1 ? '&' : '?';
    var url = this.url + sign + 'limit=${iziLimit}';
    if (data.lengthData && url.indexOf('skip=${lengthData}') == -1) {
      url = `${url}&skip=${data.lengthData}`;
    }
    return Util.applyUrl(url, data);
  }

  scrollTo() {
    let mdeContent = document.querySelector('.mde-main-container'),
      scrollTop = this.state.scrollTop || 0,
      counter = 1;

    let scrollInterval = setInterval(() => {
      let scrollHeight = mdeContent.scrollHeight;

      // just run once & reset
      if (scrollHeight >= scrollTop || counter >= 10) {
        clearInterval(scrollInterval);
        mdeContent.scrollTop = scrollTop;
      }
      ++counter;
    }, 200);
  }

  getLimit() {
    let total = 0;
    this.data.forEach((group) => {
      total += group.data.length;
    });
    this.currentLimit = total;
    return total;
    this.saveState();
  }

  processResult(data, isAppended) {
    if (!isAppended) {
      this.data.splice(0);
    }
    // if no group
    if (!this.group) {
      if (this.data.length > 0) {
        data.forEach((item) => {
          this.data[0].data.push(item);
        });
      } else if (data.length > 0) {
        this.data.push({
          group: 'NoGroup',
          data: data,
        });
      }
      //stop loading
      this.isLoading = false;
      this.getLimit();
      return;
    }
    // group by timestamp
    if (this.group.isTimeStamp) {
      this.processTimeStamp(data, this.group);
    } else {
      this.processNormalGroup(data, this.group);
    }
    //stop loading
    this.isLoading = false;
    // get current limit
    this.getLimit();
  }

  processTimeStamp(data, group) {
    let me = State.get('me'),
      today = Moment.utc().utcOffset(me.time_zone.value),
      today_start = +today.startOf('days'),
      today_end = +today.endOf('days'),
      yesterday = today.subtract(1, 'days'),
      yesterday_start = +yesterday.startOf('days'),
      yesterday_end = +yesterday.endOf('days');

    data.forEach((item) => {
      let current_group = -1,
        last_index = this.data.length - 1,
        last_group = this.data[last_index] || {},
        myDate = Moment(item[group.field]).utcOffset(me.time_zone.value);

      if (+myDate >= yesterday_start && +myDate <= yesterday_end) {
        current_group = yesterday_start;
      } else if (+myDate >= today_start && +myDate <= today_end) {
        current_group = today_start;
      } else {
        current_group = +myDate.startOf('month');
      }

      if (current_group == last_group.group) {
        this.data[last_index].data.push(item);
      } else {
        this.data.push({
          group: current_group,
          data: [item],
        });
      }
    });
  }

  processNormalGroup(data, group) {
    data.forEach((item) => {
      let last_index = this.data.length - 1,
        last_group = this.data[last_index] || {},
        current_group = item[group.field];

      if (current_group == last_group.group) {
        this.data[last_index].data.push(item);
      } else {
        this.data.push({
          group: current_group,
          data: [item],
        });
      }
    });
  }

  expand(evt, row) {
    this.data.forEach((item) => {
      item.data.forEach((r) => {
        r.is_expand = false;
      });
    });
    row.is_expand = true;
  }

  collapse(evt, row) {
    row.is_expand = false;
  }
}

@inject(Element, EventAggregator)
@customAttribute('end-table', bindingMode.twoWay)
export class EndTable {
  constructor(element, event) {
    this.element = element;
    this.event = event;
  }

  bind(bindingContext, overridingContext) {
    this.$last = overridingContext.$last;
  }

  attached() {
    if (this.$last) {
      //            this.tableJS();
      this.menuScripts();
      this.wndResizeSubscribe = this.event.subscribe(
        'izi-client-v2-ui-window-resize',
        () => {
          this.tableResize();
        }
      );
    }
    this.tableResize();
    this.tableScroll();
    this.createTR();
    if (
      document.querySelectorAll(
        '.mde-main-container .myJs_screen_scroll .myJs_TABLE'
      )[0]
    ) {
      document
        .querySelectorAll('.mde-main-container .myJs_screen_scroll')[0]
        .addEventListener('scroll', this.tableScroll, false);
      document
        .querySelectorAll('.mde-main-container .myJs_screen_scroll')[0]
        .addEventListener('scroll', this.createTR, false);
      document
        .querySelectorAll('.mde-main-container .myJs_screen_scroll')[0]
        .addEventListener('scroll', this.tableResize, false);
      document
        .querySelectorAll('.mde-main-container .myJs_screen_scroll')[0]
        .addEventListener('mouseover', this.tableResize, false);
    } else {
      document
        .querySelectorAll('.mde-main-container')[0]
        .addEventListener('scroll', this.tableScroll, false);
      document
        .querySelectorAll('.mde-main-container')[0]
        .addEventListener('scroll', this.createTR, false);
      document
        .querySelectorAll('.mde-main-container')[0]
        .addEventListener('scroll', this.tableResize, false);
      document
        .querySelectorAll('.mde-main-container')[0]
        .addEventListener('mouseover', this.tableResize, false);
    }
    this.colColor();
  }

  detached() {
    this.wndResizeSubscribe && this.wndResizeSubscribe.dispose();
    //        window.removeEventListener('resize', this.tableResize);
  }

  tableJS() {
    window.addEventListener('resize', this.tableResize);
    this.tableResize();
    this.menuScripts();
    /*window.addEventListener('load', function () {
        alert('asdasdasd');
    },false);*/
  }

  menuScripts() {
    var that = this;
    if (
      !this.element.parentElement ||
      !this.element.querySelector('.table-table-toolbar-menu')
    ) {
      return;
    }

    var menuItems = this.element.querySelectorAll(
      '.table-table-toolbar-menu .table-toolbar-menu-item'
    );
    menuItems.forEach((menu) => {
      menu.addEventListener('click', (evt) => {
        var parent = Util.closest(menu, 'mde-dropdown-menu');
        if (parent && parent.previousElementSibling) {
          parent.previousElementSibling.click();
        }
      });
    });
  }

  tableResize() {
    if (document.querySelectorAll('.mde-main-container .myJs_stop_scroll')[0]) {
      return;
    }
    // console.log("GGGGGGggg", document.querySelectorAll(".mde-main-container .myJs_stop_scroll")[0])
    // console.log("GGGGGGggg", this)
    var timeOut = 300;
    var that = document.querySelectorAll('.mde-main-container')[0];
    setTimeout(function () {
      if (!that.querySelectorAll('.myJs_TABLE_wrapper .myJs_TD')) {
        return;
      }
      var AAA = that.querySelectorAll('.myJs_TABLE');
      // console.log("AAA", AAA)
      var RRR = 0;
      if (
        !AAA[0] ||
        !AAA[0].querySelectorAll('.myJs_TBODY') ||
        !AAA[0].querySelectorAll('.myJs_TBODY .myJs_TR') ||
        !AAA[0].querySelectorAll('.myJs_TBODY .myJs_TR .myJs_TD')
      ) {
        return;
      }
      for (
        var i = 0;
        i < AAA[0].querySelectorAll('.myJs_TBODY .myJs_TR .myJs_TD').length;
        i++
      ) {
        if (!AAA[0].querySelectorAll('.myJs_TBODY .myJs_TR .myJs_TD')[i]) {
          return;
        }
        var WWW = 0;
        for (var j = 0; j < AAA.length; j++) {
          if (!AAA[j].querySelectorAll('.myJs_TBODY .myJs_TR .myJs_TD')[i]) {
            return;
          }
          var QQQ = AAA[j].querySelectorAll('.myJs_TBODY .myJs_TR .myJs_TD')[i]
            .offsetWidth;
          if (QQQ > WWW) {
            WWW = QQQ;
          }
        }
        // console.log("WWW", WWW)
        for (var x = 0; x < AAA.length; x++) {
          // console.log("AAA[x]", AAA[x])
          if (
            !AAA[x].querySelectorAll('.myJs_THEAD .myJs_TR .myJs_TD')[i] ||
            !AAA[x].querySelectorAll('.myJs_TBODY .myJs_TR .myJs_TD')[i]
          ) {
            return;
          }
          // console.log("AAA[x]RRRRRRRR", AAA[x])
          AAA[x].querySelectorAll('.myJs_THEAD .myJs_TR .myJs_TD')[
            i
          ].style.width = WWW + 'px';
          AAA[x].querySelectorAll('.myJs_TBODY .myJs_TR .myJs_TD')[
            i
          ].style.width = WWW + 'px';
          AAA[x].querySelectorAll('.myJs_THEAD')[0].style.width =
            AAA[x].offsetWidth + 'px';
          // console.log("myJs_TD", AAA[x].querySelectorAll(".myJs_THEAD .myJs_TR .myJs_TD")[i])
        }
      }
    }, timeOut);
  }

  // SCROLL || that.querySelectorAll(".myJs_stop_scroll")
  tableScroll() {
    // console.log("1GGGGGG", this)
    if (document.querySelectorAll('.mde-main-container .myJs_stop_scroll')[0]) {
      return;
    }
    if (document.querySelectorAll('.mde-main-container')[0].offsetWidth < 750) {
      return;
    }
    // console.log("2BBBBBbb", this)
    // console.log("EREWRWERRW", document.querySelectorAll(".mde-main-container")[0].offsetWidth);
    var timeOut = 300;
    var that = document.querySelectorAll('.mde-main-container')[0];
    var heightHeader = 0;
    // console.log(heightHeader)
    // setTimeout(function () {
    if (
      !that.querySelectorAll('.myJs_TABLE') ||
      !that.querySelectorAll('.myJs_TABLE_scroll')[0]
    ) {
      return;
    }
    var TTT = that.querySelectorAll('.myJs_TABLE_scroll');
    for (var i = 0; i < TTT.length; i++) {
      var itopTable = TTT[i].getBoundingClientRect().top;
      var ibottomTable = TTT[i].getBoundingClientRect().bottom;
      var iheightHeader =
        TTT[i].querySelectorAll('.myJs_THEAD')[0].offsetHeight;
      // console.log("CCCC", TTT[i].getBoundingClientRect().top, "CCCSS", TTT[i], "FGGGG", TTT[i].querySelectorAll(".myJs_THEAD")[0].offsetHeight, "JJJJ", TTT[i].getBoundingClientRect().bottom)
      // console.log("TTT[i]222", TTT[i])
      if (itopTable < heightHeader && ibottomTable > iheightHeader) {
        TTT[i].classList.add('myJs_thead_sticky');
        TTT[i].querySelectorAll('.myJs_THEAD')[0].style.top =
          heightHeader + 'px';
      } else {
        TTT[i].classList.remove('myJs_thead_sticky');
        TTT[i].querySelectorAll('.myJs_THEAD')[0].style.top = '0px';
      }
      if (TTT[i].querySelectorAll('.myJs_TR').length < 8) {
        TTT[i].classList.remove('myJs_thead_sticky');
        TTT[i].querySelectorAll('.myJs_THEAD')[0].style.top = '0px';
      }
    }
    // }, timeOut);
  }
  createTR(timeOut) {
    // console.log("3WWWWWW", document.querySelectorAll(".mde-main-container")[0].offsetWidth);

    if (document.querySelectorAll('.mde-main-container .myJs_stop_scroll')[0]) {
      return;
    }
    if (document.querySelectorAll('.mde-main-container')[0].offsetWidth < 750) {
      return;
    }
    // console.log("4hhjjkhkj", document.querySelectorAll(".mde-main-container")[0].offsetWidth);
    var timeOut = 300;
    var that = document.querySelectorAll('.mde-main-container')[0];
    setTimeout(function () {
      if (
        !that.querySelectorAll('.myJs_TABLE') ||
        !that.querySelectorAll('.myJs_TABLE_scroll')[0]
      ) {
        return;
      }
      var TTT = that.querySelectorAll('.myJs_TABLE_scroll');
      // console.log(TTT.length)
      for (var i = 0; i < TTT.length; i++) {
        var UUU = TTT[i].querySelectorAll('.myJs_TBODY')[0];
        // them tr
        // console.log("JJJJJJJ", TTT[i], UUU)
        var ntr = document.createElement('tr');
        ntr.className = 'myJs_newTR';
        // ntr.style.display = "none";
        var ntd = document.createElement('td');
        ntd.style.height =
          TTT[i].querySelectorAll('.myJs_THEAD')[0].offsetHeight + 'px';
        ntr.appendChild(ntd);
        if (UUU.querySelectorAll('.myJs_newTR')[0]) {
          return;
        }
        UUU.insertBefore(ntr, UUU.childNodes[0]);
      }
    }, timeOut);
  }
  colColor() {
    var issetReport = 'mde-table-report';
    if (
      !this.element.className.match(issetReport) ||
      !this.element.querySelectorAll('.mde-head-one')[0]
    ) {
      return;
    }
    var numColspan = this.element
      .querySelectorAll('.mde-head-one')[0]
      .getAttribute('colspan');
    var numColspanmore = numColspan * 1 + 1;
    var styleColColor =
      '<style>.mde-table-style.mde-table-report .mde-table-content tr .myJs_TD:nth-child(' +
      numColspan +
      'n + ' +
      1 +
      '){border-right:2px solid #cbcecf};</style>';
    this.element.querySelectorAll('#colColor')[0].innerHTML = styleColColor;
  }
}
