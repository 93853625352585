import _ from 'lodash';

export class App {
  configureRouter(config, router) {
    config.map([
      {
        route: ['refund'],
        moduleId: 'pages/refund/index',
        title: 'Aeon Vietnam- Return Request',
      },
      {
        route: ['/'],
        moduleId: 'pages/main/index',
        title: 'Customer Submission',
      },
    ]);
    this.router = router;
  }
}
