import 'regenerator-runtime/runtime';
import 'lib/logger';

import _ from 'lodash';

import Locale from 'lib/locale';
import State from 'lib/state';
import * as UserService from 'services/user-service';

export function configure(aurelia) {
  aurelia.use
    .standardConfiguration()
    .feature('resources/index')
    .plugin(PLATFORM.moduleName('aurelia-sortablejs'));

  aurelia.use.developmentLogging(
    process.env.NODE_ENV !== 'production' ? 'debug' : 'warn'
  );

  // const appURL = new URL(window.location.href);
  const rootElement = document.body;

  return Promise.resolve()
    .then(() => UserService.getMe())
    .then((me) => {
      State.set('me', me);

      return Locale.load(State.getStorage('me.language', 'vi'));
    })
    .then(() => aurelia.start())
    .then(() => {
      // LogManager.addAppender(serverLogs);
      // LogManager.setLevel(LogManager.logLevel.debug);

      rootElement.setAttribute('aurelia-app', '');
      aurelia.setRoot('app', rootElement);
    });
}
