'use strict';
//
//  login.js
//  handle authentication logic
//
//  Created by thanhdh on 2016-04-14.
//  Copyright 2015 Fireflyinnov. All rights reserved.
//
import {inject} from 'aurelia-framework';
import {customElement, bindable, bindingMode} from 'aurelia-framework';
import moment from 'moment';
import _ from 'lodash';
import {Countries, keys} from './data';

@customElement('intl-tel-input')
@inject(Element)
export class IntlTelInput {
  // init variables
  @bindable id = `intl-tel-input-${
    +moment.utc() + parseInt(Math.random() * 10000)
  }`; // The id of our custom select
  @bindable({defaultBindingMode: bindingMode.twoWay}) selectedCountry = 'vn';
  @bindable({defaultBindingMode: bindingMode.twoWay}) code = '84';
  @bindable({defaultBindingMode: bindingMode.twoWay}) number = null;
  @bindable mdeStyle = 'width: 100%';

  options = {
    preferredCountries: ['vn', 'us'],
  };

  /**
   * setup init dependencies
   * author : dientn
   */
  constructor(element) {
    this.element = element;
    this.input = null;
    this.countries = Countries;
    this.init();
  }

  /**
   * handle login when html has been attached to DOM
   * author : dientn
   */
  attached() {
    this.telInput = this.element.querySelector('.input-text');
    this.flagsContainer = this.element.querySelector('.flag-container');
    //this.selectedFlagInner = this.flagsContainer.querySelector('.selected-flag');
    this.selectedFlag = this.flagsContainer.querySelector('.selected-flag');
    this.countryList = this.flagsContainer.querySelector('.country-list');
    this.isGoodBrowser = Boolean(this.telInput.setSelectionRange);

    if (this.selectedCountry || this.code) {
      this.selected = _.find(this.countries, (item) => {
        return item.dialCode == this.code || item.iso2 == this.selectedCountry;
      });
      this.code = this.selected.dialCode;
      this.selectedCountry = this.selected.iso2;
    }
  }

  codeChanged(value) {
    if (value && !this.isLocalChange) {
      var country = _.find(this.countryData, ['dialCode', this.code]);
      if (country) {
        this.selectedCountry = country.iso2;
      }
      this.isLocalChange = false;
    }
  }
  init() {
    this.isMobile =
      /Android.+Mobile|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    if (this.options.preferredCountries.length > 0) {
      this.preferredCountries = Countries.filter(
        (c) => this.options.preferredCountries.indexOf(c.iso2) != -1
      );
    }
  }
  showDropdown() {
    this.setDropdownPosition();
    // update highlighting and scroll to active list item
    var activeListItem = this.countryList.querySelector('.active');
    if (activeListItem) {
      // this.highlightListItem(activeListItem);
      this.scrollTo(activeListItem);
    }
    // bind all the dropdown-related listeners: mouseover, click, click-off, keydown
    this.bindDropdownListeners();
    // update the arrow
    this.flagsContainer.querySelector('.iti-arrow').classList.add('up');
  }

  setDropdownPosition() {
    this.countryList.classList.remove('hide');
    this.dropdownHeight = this.countryList.clientHeight;
    // if (!this.isMobile) {
    //     var pos = {top: this.telInput.offsetTop, left: this.telInput.offsetLeft}, inputTop = pos.top, windowTop = $(window).scrollTop(), // dropdownFitsBelow = (dropdownBottom < windowBottom)
    //     dropdownFitsBelow = inputTop + this.telInput.outerHeight() + this.dropdownHeight < windowTop + $(window).height(), dropdownFitsAbove = inputTop - this.dropdownHeight > windowTop;
    //     // by default, the dropdown will be below the input. If we want to position it above the input, we add the dropup class.
    //     this.countryList.toggleClass("dropup", !dropdownFitsBelow && dropdownFitsAbove);
    //     // if dropdownContainer is enabled, calculate postion
    //     if (this.options.dropdownContainer) {
    //         // by default the dropdown will be directly over the input because it's not in the flow. If we want to position it below, we need to add some extra top value.
    //         var extraTop = !dropdownFitsBelow && dropdownFitsAbove ? 0 : this.telInput.innerHeight();
    //         // calculate placement
    //         this.dropdown.css({
    //             top: inputTop + extraTop,
    //             left: pos.left
    //         });
    //         // close menu on window scroll
    //         $(window).on("scroll" + this.ns, function() {
    //         });
    //     }
    // }
  }

  closeDropdown() {
    this.countryList.classList.add('hide');
    // update the arrow
    this.selectedFlag.querySelector('.iti-arrow').classList.remove('up');
    // unbind key events
    if (document.removeEventListener) {
      document.removeEventListener('keydown', this._onKeydown);
      // unbind click-off-to-close
      //$("html").off(this.ns);
      document
        .querySelector('html')
        .removeEventListener('click', this._onClick);
    } else {
      document.detachEvent('keydown', this._onKeydown);
      document.querySelector('html').detachEvent('click', this._onClick);
    }

    // unbind hover and click listeners
    //this.countryList.off(this.ns);
    // remove menu from container
    // if (this.options.dropdownContainer) {
    //     if (!this.isMobile) {
    //         $(window).off("scroll" + this.ns);
    //     }
    //     this.dropdown.detach();
    // }
  }

  // called when the user selects a list item from the dropdown
  selectListItem(evt, item) {
    this.isLocalChange = true;
    // update selected flag and active list item
    this.selected = item;
    this.selectedCountry = item.iso2;
    this.code = item.dialCode;
    this.closeDropdown();
    //this._updateDialCode(listItem.attr("data-dial-code"), true);
    // focus the input
    this.telInput.focus();
    // put cursor at end - this fix is required for FF and IE11 (with nationalMode=false i.e. auto inserting dial code), who try to put the cursor at the beginning the first time
    if (this.isGoodBrowser) {
      var len = this.telInput.value.length;
      this.telInput.setSelectionRange(len, len);
    }
  }

  scrollTo(element, middle) {
    var container = this.countryList,
      containerHeight = container.clientHeight,
      containerTop = container.getBoundingClientRect().top, //offsetTop,
      containerBottom = containerTop + containerHeight,
      elementHeight = element.clientHeight,
      elementTop = element.getBoundingClientRect().top, //.offsetTop,
      elementBottom = elementTop + elementHeight,
      newScrollTop = elementTop - containerTop + container.scrollTop,
      middleOffset = containerHeight / 2 - elementHeight / 2;

    if (elementTop < containerTop) {
      // scroll up
      if (middle) {
        newScrollTop -= middleOffset;
      }
      container.scrollTop = newScrollTop;
    } else if (elementBottom > containerBottom) {
      // scroll down
      if (middle) {
        newScrollTop += middleOffset;
      }
      //var heightDifference = containerHeight - elementHeight;
      container.scrollTop = newScrollTop;
    }
  }

  highlightListItem(item) {
    //remove class highlight from all li.country
    this.countryList.querySelectorAll('.country').forEach((element) => {
      element.classList.remove('highlight');
    });

    //add class highlight to element active
    //listItem.forEach((element)=> {
    item.classList.add('highlight');
    //});
  }

  flagsContainerKeydown(e) {
    var isDropdownHidden = this.countryList.classList.contains('hide');
    if (
      isDropdownHidden &&
      (e.which == keys.UP ||
        e.which == keys.DOWN ||
        e.which == keys.SPACE ||
        e.which == keys.ENTER)
    ) {
      // prevent form from being submitted if "ENTER" was pressed
      e.preventDefault();
      // prevent event from being handled again by document
      e.stopPropagation();
      this.showDropdown();
    }
    // allow navigation from dropdown to input on TAB
    if (e.which == keys.TAB) {
      this.closeDropdown();
    }
  }

  selectedFlagClick(evt) {
    if (
      this.countryList.classList.contains('hide') &&
      !this.telInput.disabled &&
      !this.telInput.readonly
    ) {
      this.showDropdown();
    } else if (!this.countryList.classList.contains('hide')) {
      this.closeDropdown();
    }
  }

  // we only bind dropdown listeners when the dropdown is open
  bindDropdownListeners() {
    // click off to close
    // (except when this initial opening click is bubbling up)
    // we cannot just stopPropagation as it may be needed to close another instance
    var isOpening = true;
    this._onClick = function (e) {
      if (!isOpening) {
      }
      isOpening = false;
    };

    // listen for up/down scrolling, enter to select, or letters to jump to country name.
    // use keydown as keypress doesn't fire for non-char keys and we want to catch if they
    // just hit down and hold it to scroll down (no keyup event).
    var query = '',
      queryTimer = null;
    this._onKeydown = function (e) {
      // prevent down key from scrolling the whole page,
      // and enter key from submitting a form etc
      e.preventDefault();
      if (e.which == keys.UP || e.which == keys.DOWN) {
        // up and down to navigate
      } else if (e.which == keys.ENTER) {
        // enter to select
      } else if (e.which == keys.ESC) {
        // esc to close
      } else if (
        (e.which >= keys.A && e.which <= keys.Z) ||
        e.which == keys.SPACE
      ) {
        // upper case letters (note: keyup/keydown only return upper case letters)
        if (queryTimer) {
          clearTimeout(queryTimer);
        }
        query += String.fromCharCode(e.which);
        // if the timer hits 1 second, reset the query
        queryTimer = setTimeout(function () {
          query = '';
        }, 1e3);
      }
    };
    if (document.addEventListener) {
      // bind click-off-to-close
      document.querySelector('html').addEventListener('click', this._onClick);
      document.addEventListener('keydown', this._onKeydown);
    } else {
      document.querySelector('html').attachEvent('click', this._onClick);
      document.attachEvent('keydown', this._onKeydown);
    }
  }
  // highlight the next/prev item in the list (and ensure it is visible)
  handleUpDownKey(key) {
    var current = this.countryList.querySelector('.highlight');
    var next =
      key == keys.UP
        ? current.previousElementSibling
        : current.nextElementSibling;
    if (next) {
      // skip the divider
      if (next.classList.contains('divider')) {
        next =
          key == keys.UP
            ? next.previousElementSibling
            : next.nextElementSibling;
      }
      this.highlightListItem(next);
      this.scrollTo(next);
    }
  }

  // select the currently highlighted item
  handleEnterKey() {
    var currentCountry = this.countryList.querySelector(
      '.highlight:first-child'
    );
    if (currentCountry) {
      var countryCode = currentCountry.getAttribute('data-country-code');
      var country = _.find(this.countryData, [
        'dialCode',
        countryCode.toString(),
      ]);
      this.selectListItem(null, country);
    }
  }

  // find the first list item whose name starts with the query string
  searchForCountry(query) {
    for (var i = 0; i < this.countries.length; i++) {
      if (this.startsWith(this.countries[i].name, query)) {
        var item = this.countryList.querySelector(
          'li[data-country-code=' + this.countries[i].iso2 + ']:not(preferred)'
        );
        // update scroll
        this.selected = this.countries[i];
        this.scrollTo(item, true);
        break;
      }
    }
  }

  // check if (uppercase) string a starts with string b
  startsWith(a, b) {
    return a.substr(0, b.length).toUpperCase() == b;
  }

  getOffset(element, container) {
    docElem = container || document;
    var box = {top: 0, left: 0};
    if (typeof element.getBoundingClientRect !== strundefined) {
      box = element.getBoundingClientRect();
    }

    return {
      top:
        box.top +
        (window.pageYOffset || docElem.scrollTop) -
        (docElem.clientTop || 0),
      left:
        box.left +
        (window.pageXOffset || docElem.scrollLeft) -
        (docElem.clientLeft || 0),
    };
  }
}
