export function newEvent(eventName, data) {
  let event;
  if (window.CustomEvent) {
    event = new CustomEvent(eventName || 'change', {
      detail: data,
      bubbles: true,
    });
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(eventName || 'change', true, true, data);
  }
  return event;
}

export function fireEvent(element, data, event) {
  if (typeof element === 'string') {
    element = document.getElementById(element);
  }
  
  if (!element) {
    return;
  }

  let changeEvent = newEvent(event, {value: data});
  if (element.length >= 0) {
    element.forEach((item) => {
      item.dispatchEvent(changeEvent);
    });
    return;
  }
  element.dispatchEvent(changeEvent);
}

export function closest(elem, query) {
  var doms = [elem],
    cur_dom;
  while (doms.length) {
    cur_dom = doms.pop();
    if (!cur_dom) {
      return null;
    }
    if (cur_dom.matches && cur_dom.matches(query)) {
      return cur_dom;
    }
    if (cur_dom.mozMatchesSelector && cur_dom.mozMatchesSelector(query)) {
      return cur_dom;
    }
    if (cur_dom.msMatchesSelector && cur_dom.msMatchesSelector(query)) {
      return cur_dom;
    }
    if (cur_dom.webkitMatchesSelector && cur_dom.webkitMatchesSelector(query)) {
      return cur_dom;
    }
    if (cur_dom.oMatchesSelector && cur_dom.oMatchesSelector(query)) {
      return cur_dom;
    }
    if (cur_dom.parentNode != document.body) {
      doms.push(cur_dom.parentNode);
    }
  }
  return null;
}

export function applyUrl(str, obj) {
  if (!obj || !str) {
    return str;
  }
  var arr_var = str.match(/\$\{[\w0-9]+\}/g) || [];
  //need to unique arr_var
  arr_var.forEach((item) => {
    var data = item.replace(/[\$\{\}]/g, '');
    str = str.replace(item, obj[data] || '');
  });
  return str;
}
