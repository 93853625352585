const version = ENV.APP_VERSION || '0.0.1';
const env = ENV.APP_ENV || 'local';

const config = {
  env,
  version,
  api: '/api',

  pagingLimit: 15,

  local: {
    cache: false,
    encrypt: false,
    captcha: {
      siteKey: ENV.APP_CAPTCHA_SITE_KEY,
    },
  },
  stage: {
    cache: false,
    encrypt: false,
    captcha: {
      siteKey: ENV.APP_CAPTCHA_SITE_KEY,
    },
  },
  prod: {
    cache: true,
    encrypt: true,
    captcha: {
      siteKey: ENV.APP_CAPTCHA_SITE_KEY,
    },
  },
};

export default config;
