import {customAttribute, inject, bindingMode} from 'aurelia-framework';

@customAttribute('checked', bindingMode.twoWay)
@inject(Element)
export class Checked {
  constructor(element) {
    this.element = element;
  }

  attached() {
    this.element.addEventListener(
      'change',
      (e) => (this.value = this.element.checked)
    );
  }

  detached() {
    this.element.removeEventListener('change', (e) => (this.value = false));
  }
  valueChanged(newValue) {
    this.element.checked = newValue;
    if (this.element.parentElement) {
      if (newValue) {
        this.element.parentElement.classList.add('is-checked');
      } else {
        this.element.parentElement.classList.remove('is-checked');
      }
    }
  }
}
