// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../assets/img/logo_en.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../../assets/img/logo.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../../assets/img/background.png", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<template>\n  <div class=\"a-header\">\n    <div class=\"logo\"  if.bind=\"urlLogo\">\n      <img style=\"max-width:240px;\"\n        src=\"${urlLogo}\"\n        alt=\"Logo AEON\"\n      />\n  </div>\n    <div class=\"logo\" if.bind=\"!urlLogo\">\n      <img\n        show.bind=\"language==='en'\"\n        src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"\n        alt=\"Logo AEON\"\n      />\n      <img\n        show.bind=\"language==='vi'\"\n        src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"\n        alt=\"Logo AEON\"\n      />\n    </div>\n  <div class=\"a-background\">\n    <img\n      class=\"a-background-img\"\n      src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"\n      alt=\"background aeon\"\n    />\n    <div class=\"a-background-top\"></div>\n  </div>\n</template>\n";
// Exports
export default code;