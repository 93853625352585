import _ from 'lodash';
import Bluebird from 'bluebird';
import Moment from 'moment';

import Config from './config';
import State from './state';
import {debug} from './logger';
import * as CommonService from 'services/common.v2';

const TRANSLATION = 'translation';
const MESSAGE = 'message';

const _locale = {};

export const load = async (lang = 'en', isForced = false) => {
  const cache = !isForced && Config.get('cache', true);
  const version = Config.get('version', '0.0.0-a');

  const storedLocale = await State.getStorage('locale');

  if (cache && !_.isEmpty(storedLocale) && storedLocale.version === version) {
    _.assign(_locale, storedLocale);

    return;
  }

  try {
    const [translation, message] = await Bluebird.all([
      CommonService.GET(`${lang}/translation.json`, {baseUrl: 'locale/'}),
      CommonService.GET(`${lang}/message.json`, {baseUrl: 'locale/'}),
    ]);

    _locale.translation = translation;
    _locale.message = message;
    _locale.version = version;

    await State.setStorage('locale', _locale);
    debug('[locale][load] done');
  } catch (err) {
    console.error('[locale][load] error', err);
  }
};

const parsePath = (value, namespace) => {
  let valuePaths = [];

  if (_.isArray(value)) {
    valuePaths = value;
  } else {
    valuePaths = _.split(value, /:|\./);
  }
  const [first] = valuePaths.join('.').split('.');

  const nsp = _locale[first] ? first : namespace;
  const transPath = _locale[first] ? valuePaths.slice(1) : valuePaths.slice(0);

  return [nsp, transPath];
};

const parseMessage = (message, params) => {
  if (!_.isNil(params)) {
    return _.template(message)(params);
  }

  return message;
};

export const translate = (value, params, defaultValue) => {
  const [nsp, transPath] = parsePath(value, TRANSLATION);

  const translated = _.get(
    _locale[nsp],
    transPath,
    _.get(_locale, [nsp, transPath.join('.')])
  );

  if (_.isEmpty(transPath) || _.isNil(translated)) {
    console.groupCollapsed(`[locale][translate] '${transPath.join('.')}'`);
    console.trace();
    console.groupEnd();
  }

  return _.isNil(translated)
    ? defaultValue || _.join(_.castArray(value), '.')
    : parseMessage(translated, params);
};

export const translateHtml = (value, params) => {
  const result = translate(value, params);

  return _.escape(result);
};

export const message = (value, params, defaultValue) => {
  const [nsp, transPath] = parsePath(value, MESSAGE);

  const translated = _.get(
    _locale[nsp],
    transPath,
    _.get(_locale, [nsp, transPath.join('.')])
  );

  if (_.isEmpty(transPath) || _.isNil(translated)) {
    console.groupCollapsed(`[locale][message] '${transPath.join('.')}'`);
    console.trace();
    console.groupEnd();
  }

  return _.isNil(translated)
    ? defaultValue || _.join(_.castArray(value), '.')
    : parseMessage(translated, params);
};

export const prefix = (path) => {
  const prefixPath = _.isArray(path) ? path : _.split(path, '.');

  const prefixMerge = (value, namespace) => {
    const [nsp, transPath] = parsePath(value, namespace);

    return [nsp, ...prefixPath, ...transPath];
  };

  return {
    prefix: (value) => prefix(_.concat(prefixPath, _.castArray(value))),
    translate: (value, ...args) =>
      translate(prefixMerge(value, TRANSLATION), ...args),
    translateHtml: (value, ...args) =>
      translateHtml(prefixMerge(value, TRANSLATION), ...args),
    message: (value, ...args) => message(prefixMerge(value, MESSAGE), ...args),
  };
};

export const formatDate = (value) => {
  const lang = State.get('me');

  const isValid = Moment(new Date(value));
  if (!isValid) {
    return '';
  }

  if (lang === 'vi') {
    return Moment(value).format('DD/MM/YYYY');
  }

  return Moment(value).format('MM/DD/YYYY');
};

export const formatDateTime = (value) => {
  const lang = State.get('me');

  const isValid = Moment(new Date(value));
  if (!isValid) {
    return '';
  }

  if (lang === 'vi') {
    return Moment(value).format('DD/MM/YYYY h:mm');
  }

  return Moment(value).format('MM/DD/YYYY hh:mm');
};

export const changeLocale = async (lng) => {
  await load(lng, true);

  State.setStorage('me.language', lng);
};

export default {
  load,
  prefix,
  translate,
  message,
  formatDate,
  formatDateTime,
  changeLocale,
};
