import {GET, POST, PUT, DELETE, FETCH} from './common.v2';
import _ from 'lodash';

const responseData = (response) => {
  try {
    const {data, error, success} = response;

    return {
      data: _.get(data, 'data'),
      error: _.get(error, 'errors', error),
      success,
    };
  } catch (err) {
    return {
      error: err,
      success: false,
    };
  }
};

export const addRefund = async (data) => {
  const response = await FETCH(`refund/submit`, {data});

  return responseData(response);
};

export const listDefaultValues = async () => {
  const response = await GET(`custom-field/refund/default`);

  return responseData(response);
};

export const getDefaultRefundExchange = async () => {
  const response = await GET(`custom-field/refund/refund-exchange`);

  return responseData(response);
};
