import {inject, customAttribute} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Events} from 'lib/enum';
import * as Locale from 'lib/locale';

@customAttribute('t')
@inject(Element, EventAggregator)
export class Translate {
  /**
   * setup init dependencies
   * @author : thanhdh
   */
  constructor(element, event, locales) {
    this.element = element;
    this.event = event;
  }

  /**
   * bind t attribute
   * @author : thanhdh
   */
  attached() {
    this.subscription = this.event.subscribe(Events.langChanged, () => {
      if (!this.key) {
        return;
      }
      this.element.innerHTML = Locale.translate(this.key);
    });
  }

  /**
   * unbind t attribute
   * @author : thanhdh
   */
  detached() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  /**
   * handle value changed
   * @author : thanhdh
   */
  valueChanged(newValue) {
    this.key = newValue;
    this.element.innerHTML = Locale.translate(this.key);
  }
}
