import {inject, customElement, bindable} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';
import moment from 'moment';

import {debug} from 'lib/logger';

import Notifier from 'lib/notifier';
import Locale from 'lib/locale';
import State from 'lib/state';

import * as Util from 'lib/util';

@customElement('mde-datepicker-event')
@inject(Element, BindingSignaler)
export class MdeDatepickerEvent {
  //changable
  @bindable diffFlag;
  @bindable defaultValue;
  @bindable type;

  @bindable isDisabled = false;

  @bindable mdeStyle = '';
  @bindable mdeClass = '';
  constructor(element, signaler) {
    this.element = element;
    this.signaler = signaler;
    this.me = State.get('me') || {};
    this.moment = moment;

    this.helper = new Helper(this.moment, this.me);

    this.datePicker = new DatePicker(signaler, this.helper);
    this.timePicker = new TimePicker(signaler, this.helper);

    this.view = {
      type: 'date',
      datepicker: this.datePicker,
      timepicker: this.timePicker,
      getText: () => this.getText(),
      getPlaceholder: () => this.getPlaceholder(),
    };

    this.name =
      this.constructor.name.padEnd(30, '-') +
      ' ' +
      Math.random().toString().substring(-3);
  }

  attached() {
    this.datePicker.bindFunctions({
      onValueChanged: (value) => this.onDateChanged(value),
    });

    this.timePicker.bindFunctions({
      onValueChanged: (value) => this.onTimeChanged(value),
    });

    this.moment.locale(this.me.language);
    this.helper.init({
      lng: this.me.language,
    });
    this.WidthJS();
  }

  defaultValueChanged() {
    if (!this.last_default_value) {
      this.last_default_value = this.defaultValue;
      this.initData();
    }
  }

  bind() {
    this.initData();
  }

  diffFlagChanged() {
    this.last_default_value = this.defaultValue;
    this.view.type = 'date';
    this.initData();
  }

  initData() {
    this.initDateTimePicker();
  }

  initDateTimePicker() {
    debug(this.name, 'initDateTimePicker', this.defaultValue);
    this.is_clear_selected =
      this.defaultValue === undefined || this.defaultValue === null;
    this.datePicker.init(this.defaultValue);
    this.timePicker.init(this.defaultValue);

    this.is_initialized = true;
    this.signaler.signal('cs-update-view-input');
  }

  onDateChanged(value) {
    debug('on Date changed event');
    this.is_clear_selected = false;
    this.timePicker.setDate(value);
    this.onValueChanged();
    this.WidthJS();
  }

  onTimeChanged() {
    debug('on Time changed event');
    this.is_clear_selected = false;
    this.onValueChanged();
  }

  onValueChanged() {
    this.signaler.signal('cs-update-view-input');
    Util.fireEvent(this.element, {
      value: this.getValue(),
    });
  }

  chooseView(type) {
    this.view.type = type;
  }

  discardChanges() {
    debug('discard changes to', this.defaultValue);
    if (this.defaultValue) {
      this.initDateTimePicker();
    } else {
      this.is_clear_selected = true;
    }
    debug('discard changes setted', this.getValue());
    this.onValueChanged();
  }

  getText() {
    if (!this.is_initialized || this.is_clear_selected) {
      return '';
    }
    let text = this.datePicker.selectedInputText();
    if (this.type == 'datetime') {
      text += ' ' + this.timePicker.selectedInputText();
    }
    return text;
  }

  getValue() {
    if (this.is_clear_selected) {
      return undefined;
    }

    let date = this.helper.moment(this.datePicker.getSelected()).startOf('day');
    if (this.type == 'datetime') {
      let time = this.helper.moment(this.timePicker.getSelected());
      date.set({
        minutes: time.minutes(),
        hours: time.hours(),
        seconds: 0,
        milliseconds: 0,
      });
    }
    return this.helper.stdToUTC(+date);
  }

  getPlaceholder() {
    let placeholder = this.helper.dateFormat();
    if (this.type == 'datetime') {
      placeholder += ' ' + this.helper.timeFormat();
    }
    return placeholder;
  }

  clearSelected() {
    this.is_clear_selected = true;
    this.onValueChanged();

    this.view.is_choosing = false;
  }

  viewDatePicker() {
    if (this.is_clear_selected) {
      this.is_clear_selected = false;
      this.initData();
    }
    this.view.is_choosing = true;
  }

  hideDatePicker() {
    this.view.is_choosing = false;
  }

  WidthJS() {
    let findName = '.mde-date-time-wrapper';
    let findNameInput = findName + ' input';
    if (
      !this.element.querySelector('.mde-add-maxWidth') ||
      !this.element.querySelector('.mde-getWidth') ||
      !this.element.querySelector(findName)
    ) {
      return;
    }
    var that = this.element;
    var timeOut = 600;
    if (!this.element.querySelector('.mde-results-content')) {
      timeOut = 600;
    } else {
      let getItemFirst = this.element.querySelector(
        '.mde-results-content'
      ).children;
      if (getItemFirst.length == 0) {
        timeOut = 1000;
      }
    }

    setTimeout(function () {
      let getItemSecond = that.querySelector(findNameInput).value;
      if (getItemSecond.split(' ', 3).length < 2) {
        getItemSecond = that.querySelector(findNameInput).placeholder;
      }
      that.querySelector('.mde-getWidth .mde-getWidthContent').innerHTML =
        getItemSecond;
      var getWidth = that.querySelector(
        '.mde-getWidth .mde-getWidthContent'
      ).offsetWidth;
      that.querySelector(findName).style.width = 100 + '%';
      if (getWidth < 100) {
        that.querySelector(findName).style.maxWidth = 150 + 'px';
      } else {
        that.querySelector(findName).style.maxWidth = getWidth + 60 + 'px';
      }
    }, timeOut);
  }
}

class DatePicker {
  constructor(signaler, helper) {
    this.signaler = signaler;

    this.helper = helper;

    this.calendar = {
      date: this.helper.moment().startOf('day'),
      prefix: [],
      in: [],
      suffix: [],
    };
    this.selected = this.helper.moment().startOf('day');

    this.yearsPerRange = 12;
    this.months = [];
    this.years = [];

    this.currentView = 'daysOfMonth';

    this.events = {
      listeners: {},
      get onValueChanged() {
        return this.listeners.onValueChanged || function () {};
      },
    };
  }

  bindFunctions(listeners) {
    Object.assign(this.events.listeners, listeners);
  }

  init(value, options) {
    if (value) {
      let selected = this.helper.moment(value, 'utc');
      this.initCalendar(selected);
      this.selectDate(selected);
    } else {
      this.selectToday();
    }
    this.initMonths();
    this.initYears();
  }

  initMonths() {
    this.months.splice(0);
    for (let i = 0; i < 12; i++) {
      this.months.push({
        name: this.helper.moment().month(i).format('MMM'),
        month: i + 1,
      });
    }
  }

  initCalendar(date) {
    let ndate = date.startOf('day'),
      calendar = this.helper.listDaysOfMonthAsMoment(
        ndate.month() + 1,
        ndate.year()
      );
    this.calendar.date = ndate;
    Object.assign(this.calendar, calendar);
    this.signaler.signal('cs-update-calendar-view-date');
  }

  initYears() {
    this.years.splice(0);
    this.years = this.helper.listYearsOfRange(
      this.calendar.date.year(),
      this.yearsPerRange
    );
    this.signaler.signal('cs-update-calendar-view-year-range');
  }

  isSelected(d) {
    if (!d || !this.selected) {
      return false;
    }
    let s_dayOfYear = this.selected.dayOfYear(),
      d_dayOfYear = d.dayOfYear(),
      s_year = this.selected.year(),
      d_year = d.year();
    return s_dayOfYear == d_dayOfYear && s_year == d_year;
  }

  onSelected() {
    this.events.onValueChanged(+this.selected);
  }

  selectedYear() {
    return this.selected ? this.selected.year() : '--';
  }

  selectedHeaderText() {
    return this.selected
      ? this.selected.format(this.helper.dateHeaderFormat())
      : '--';
  }

  selectedInputText() {
    return this.selected
      ? this.selected.format(this.helper.dateFormat())
      : '--';
  }

  getSelected() {
    return this.selected;
  }

  calendarText() {
    return this.calendar.date.format('MMMM YYYY');
  }

  calendarYear() {
    return this.calendar.date.year();
  }

  selectDate(d) {
    if (!d) {
      return;
    }
    this.selected = this.helper.moment(d.startOf('day'));
    this.selectedTimestamp = +this.selected;
    this.signaler.signal('cs-update-date-view-selected');
  }

  selectToday() {
    let now = this.helper.moment();
    this.initCalendar(now);
    this.selectDate(now);
    this.changeView('daysOfMonth');
  }

  chooseDay(d) {
    if (!d.value || this.isSelected(d.value)) {
      return;
    }
    let begin_current_month = +this.calendar.date.startOf('month'),
      end_current_month = +this.calendar.date.endOf('month');

    if (+d.value < begin_current_month) {
      this.previousMonth();
    } else if (+d.value > end_current_month) {
      this.nextMonth();
    }
    this.selectDate(d.value);
    this.onSelected();
  }

  chooseMonth(month) {
    let date = this.calendar.date;
    this.initCalendar(date.month(month - 1));

    this.changeView('daysOfMonth');
  }

  chooseYear(year) {
    this.calendar.date.year(year);

    this.changeView('monthsOfYear');
  }

  chooseToday() {
    this.selectToday();
    this.onSelected();
  }

  previousMonth() {
    let date = this.calendar.date;
    if (date.month() == 0 && date.year() == 1970) {
      return;
    }
    this.initCalendar(date.add(-1, 'month'));
  }

  nextMonth() {
    let date = this.calendar.date;
    this.initCalendar(date.add(1, 'month'));
  }

  previousYear() {
    if (this.calendar.date.year() == 1970) {
      return;
    }
    this.calendar.date.add(-1, 'year');
    this.signaler.signal('cs-update-calendar-view-year');
  }

  nextYear() {
    this.calendar.date.add(1, 'year');
    this.signaler.signal('cs-update-calendar-view-year');
  }

  previousYearsRange() {
    let year = this.calendar.date.year(),
      max_range = year - 1970,
      mod = max_range % this.yearsPerRange;

    if (max_range < this.yearsPerRange) {
      return;
    }

    this.calendar.date.year(year - mod - this.yearsPerRange);
    this.initYears();
  }

  nextYearsRange() {
    let year = this.calendar.date.year(),
      max_range = year - 1970,
      mod = max_range % this.yearsPerRange;

    this.calendar.date.year(year - mod + this.yearsPerRange);
    this.initYears();
  }

  changeView(view) {
    this.currentView = view;
    if (view != 'daysOfMonth') {
      this.signaler.signal('cs-update-calendar-view-year');
    }
  }

  viewMonthsOfYear() {
    this.changeView('monthsOfYear');
  }

  viewYearsRange() {
    this.changeView('Years');
  }
}

class TimePicker {
  constructor(signaler, helper) {
    this.signaler = signaler;

    this.helper = helper;

    this.selected = this.helper.moment();

    this.events = {
      listeners: {},
      get onValueChanged() {
        return this.listeners.onValueChanged || function () {};
      },
    };
  }

  bindFunctions(listeners) {
    Object.assign(this.events.listeners, listeners);
  }

  init(value, options) {
    if (value) {
      let selected = this.helper.moment(value, 'utc');
      this.selectTime(selected.toObject(), false);
    } else {
      this.chooseNow(false);
    }
  }

  getSelected() {
    return this.selected;
  }

  selectedHeaderText() {
    return this.selected
      ? this.selected.format(this.helper.timeHeaderFormat())
      : '--';
  }

  selectedInputText() {
    return this.selected
      ? this.selected.format(this.helper.timeFormat())
      : '--';
  }

  selectedMinutesText() {
    if (!this.selected) {
      return 0;
    }
    return this.selected.format('mm');
  }

  isSelected(hour) {
    if (!this.selected) {
      return false;
    }
    return this.selected.hour() % 12 == hour % 12;
  }

  selectTime(value, is_fired = true) {
    if (!this.selected) {
      this.selected = this.helper.moment().startOf('day');
    }
    value.seconds = 0;
    value.milliseconds = 0;
    this.selected.set(value);
    this.signaler.signal('cs-update-time-view-selected');

    is_fired && this.events.onValueChanged(+this.selected);
  }

  setDate(timestamp) {
    let date = this.helper.moment(timestamp, 'std');
    this.selectTime(
      {
        years: date.year(),
        months: date.month(),
        date: date.date(),
      },
      false
    );
  }

  chooseHour(hour) {
    this.selectTime({
      hour: hour,
    });
  }

  choosePeriod(period) {
    let cur_hour = this.selected.hour();
    this.selectTime({
      hour: (cur_hour + period + 24) % 24,
    });
  }

  chooseNow(is_fired = true) {
    let now = this.helper.moment();
    this.selectTime(
      {
        hour: now.hour(),
        minute: now.minute(),
      },
      is_fired
    );
  }

  previousNext() {
    let cur_m = this.selected.minute();
    this.selectTime({
      minute: (cur_m + 59) % 60,
    });
  }

  editMinute(val) {
    let cur_m = this.selected.minute();
    this.selectTime({
      minute: (cur_m + val + 60) % 60,
    });
  }

  mMouseDown(val) {
    let a = 1;
    this.editMinute(val);
    this.minuteMouseDownInterval = setInterval(() => {
      this.editMinute(val * a);
      if (a < 3) {
        a += 1;
      }
    }, 200);
  }

  mMouseUp(val) {
    clearInterval(this.minuteMouseDownInterval);
  }
}

class Helper {
  constructor(moment, me) {
    this._moment = moment;
    this.me = me;

    this.lng = me.language;
    this.std_format = 'YYYY-MM-DDTHH:mm:ssZ';
  }

  init(options) {
    if (options.lng) {
      this.lng = options.lng;
    }
  }

  dateToTime(date) {
    let mm = date;
    if (!date._isAMomentObject) {
      mm = this.moment()
        .date(date.day)
        .month(date.month - 1)
        .year(date.year);
    }
    return +mm.startOf('day');
  }

  //0: sunday, 7: saturday
  weekDay(d, m, y) {
    let tm = m < 3 ? m + 13 : m + 1,
      ty = m < 3 ? y - 1 : y;

    let zellerResult =
      d +
      Math.floor(2.6 * tm) +
      (ty % 100) +
      Math.floor((ty % 100) / 4) +
      Math.floor(ty / 400) -
      2 * Math.floor(ty / 100);

    return (((zellerResult % 7) + 6) % 7) + 1;
  }

  listYearsOfRange(y, r) {
    let year = y,
      max_range = year - 1970 + 1,
      mod = max_range % r,
      res = [];

    if (!mod) {
      for (let i = year - r + 1; i <= year; i++) {
        res.push(i);
      }
    } else {
      for (let i = 0, counter = year - mod + 1; i < r; i++) {
        res.push(counter++);
      }
    }
    return res;
  }

  getDayObject(d) {
    let mm = this.dateToMoment(d.day, d.month - 1, d.year);
    return {
      name: d.day,
      value: mm,
      timestamp: +mm,
    };
  }

  listDaysOfMonthAsMoment(m, y) {
    let res = this.listDaysOfMonth(m, y);
    return {
      prefix: res.prefix.map((d) => this.getDayObject(d)),
      in: res.in.map((d) => this.getDayObject(d)),
      suffix: res.suffix.map((d) => this.getDayObject(d)),
    };
  }

  listDaysOfMonth(m, y) {
    let res = {
        prefix: [],
        in: [],
        suffix: [],
      },
      days_of_last_month =
        m > 1 ? this.daysOfMonth(m - 1, y) : this.daysOfMonth(12, y - 1),
      days_of_month = this.daysOfMonth(m, y),
      first_week_day = this.weekDay(1, m, y),
      last_week_day = this.weekDay(days_of_month, m, y);

    for (let i = first_week_day - 2; i >= 0; i--) {
      if (m == 1 && y - 1 < 1970) {
        res.prefix.push({
          day: 0,
        });
        continue;
      }
      res.prefix.push({
        day: days_of_last_month - i,
        month: m > 1 ? m - 1 : 12,
        year: m > 1 ? y : y - 1,
      });
    }
    for (let i = 1; i <= days_of_month; i++) {
      res.in.push({
        day: i,
        month: m,
        year: y,
      });
    }
    for (let i = last_week_day + 1, counter = 1; i <= 7; i++) {
      res.suffix.push({
        day: counter++,
        month: m < 12 ? m + 1 : 1,
        year: m < 12 ? y : y + 1,
      });
    }
    return res;
  }

  daysOfMonth(m, y) {
    if (m == 2) {
      return 28 + this.isLeapYear(y);
    }
    if ([1, 3, 5, 7, 8, 10, 12].includes(m)) {
      return 31;
    }
    return 30;
  }

  isLeapYear(y) {
    return y % 400 == 0 || (y % 4 == 0 && y % 100);
  }

  dateFormat() {
    return this.lng == 'vi' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
  }

  dateHeaderFormat() {
    return this.lng == 'vi' ? 'ddd, DD MMM' : 'ddd, MMM DD';
  }

  timeHeaderFormat() {
    return 'HH:mm A';
  }

  timeFormat() {
    return this.lng == 'vi' ? 'HH:mm' : 'HH:mm';
  }

  dateToMoment(d, m, y) {
    if (!d) {
      return null;
    }
    return this.moment().startOf('day').month(m).year(y).date(d);
  }

  stdToUTC(value) {
    let mm = this.moment(value, 'std');
    return +this._moment(mm.format(this.std_format), this.std_format).utc();
  }

  moment(value, flag = 'local') {
    if (value && typeof value == 'object' && value._isAMomentObject) {
      return this._moment(value);
    }
    const timeZone = _.get(this.me, 'time_zone.value', 7);
    if (flag == 'utc') {
      return this._moment.utc(value).utcOffset(timeZone * 60);
    }
    return this._moment(value).utcOffset(timeZone * 60, flag != 'std');
  }
}
