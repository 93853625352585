import _ from 'lodash';

export class StringifyValueConverter {
  toView(value) {
    return JSON.stringify(value);
  }
}

export class ToJsonValueConverter {
  toView(value, returnField) {
    return JSON.parse(value)[returnField];
  }
}

export class DecodeUrlValueConverter {
  toView(value) {
    var base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    if (base64regex.test(value)) {
      return window.decodeURIComponent(value);
    } else {
      return value;
    }
  }
}

export class SubStrValueConverter {
  toView(value, from, to) {
    if (!value) {
      return '';
    }
    let length = value.length;
    if (to > length) {
      to = length;
    }
    return length > to ? value.substr(from, to) + '...' : value;
  }
}

export class CapitalizeValueConverter {
  toView(value, from, to) {
    if (!value) {
      return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

export class StripTagsValueConverter {
  toView(value) {
    //        var str = "<hi>How are you<hi><table><tr>I<tr><table>love cake<g>"
    value = (value || '').replace(/<{1}[^<>]{1,}>{1}/g, ' ');
    return value;
  }
}
