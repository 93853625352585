import {inject, customElement, bindable, bindingMode} from 'aurelia-framework';
import Moment from 'moment';
import _ from 'lodash';

import State from 'lib/state';
import * as Util from 'lib/util';

import {DatetimeFormatValueConverter} from '../value-converters/datetime-format';

@customElement('datetime-picker')
@inject(Element, DatetimeFormatValueConverter)
export class Datetime {
  // init variables
  @bindable id = `datetime-picker-${
    +Moment.utc() + parseInt(Math.random() * 10000)
  }`; // The id of our custom select
  @bindable({
    defaultBindingMode: bindingMode.twoWay,
  })
  value = +Moment(); // The label/option values
  @bindable options = {
    format: 'DD/MM/YYYY',
    timepicker: false,
  };
  @bindable mdeStyle = '';
  @bindable mdeClass = '';
  _options = {};
  //    @bindable mdeStyle = 'width:100%';
  showPicker = false;
  states = {
    currentView: 'date',
    yearElements: [],
  };

  /**
   * setup init dependencies
   * author : dientn
   */
  constructor(element, datetimeConverter) {
    this.element = element;
    this.datetimeConverter = datetimeConverter;
    this.me = State.get('me') || {};
    this.lng = State.get('me.language', 'en');
    this.timezone = State.get('me.time_zone.value', 7);

    Moment.locale(this.lng);
    this.moment = Moment;
    this.localeData = Moment.localeData();
    this.init();
    this._options = this.genarateOptions();
    this.updateDate();
    this.dateInterval = setInterval(() => this.updateDate(), 1000);
  }

  /**
   * handle value changed
   * author : dientn
   */
  valueChanged(newValue) {
    if (newValue) {
      var value = this.updateState();
      this.valueTitle = this.datetimeConverter.toView(
        this.states.date,
        this._options.format,
        this._options.timepicker
      ); // this.states.date.format(this._options.format);
      if (this.valueTimeout) {
        clearTimeout(this.valueTimeout);
      }
      this.valueTimeout = setTimeout(() => {
        Util.fireEvent(this.element, value);
      }, 250);
    } else {
      this.states.viewDate = Moment();
      this.valueTitle = '';
    }

    this.states.currentView = this._options.viewMode;
    this.setView(this.states.currentView);
    this.updateTitle();
  }

  optionsChanged(value) {
    this._options = Object.assign(this._options, this.options || {});
    this.states.currentView = this._options.viewMode;
    this.setView(this.states.currentView);
    if (!this._options.timepicker) {
      this._options.format = this.lng == 'vi' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
    } else {
      this._options.format =
        this.lng == 'vi' ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm';
    }
    this.updateState();
    this.updateTitle();
  }

  updateState() {
    var value = null;
    if (typeof this.value == 'number') {
      value = +Moment(this.value).utcOffset(this.timezone);
    } else {
      var number = Number(this.value);
      if (isNaN(number)) {
        value = this.datetimeConverter.fromView(
          this.value,
          this._options.timepicker
        );
      } else {
        value = +Moment(number).utcOffset(this.timezone);
      }
      this.states.viewDate = Moment(value);
      this.states.date = Moment(value);
    }
    return value;
  }

  /**
   * handle datetime picker when html has been attached to DOM
   * author : dientn
   */
  attached() {
    // this._options = this.genarateOptions();
    document.addEventListener('mousedown', (evt) => {
      this.clickedOutside(evt);
      this.WidthJS();
    });
    document.addEventListener('touchend', (evt) => {
      this.clickedOutside(evt);
      this.WidthJS();
    });
    this.WidthJS();
  }

  detached() {
    clearInterval(this.dateInterval);
  }

  genarateOptions() {
    return {
      format: this.lng == 'vi' ? 'DD/MM/YYYY' : 'MM/DD/YYYY',
      dayViewHeaderFormat: 'MMMM YYYY',
      dayTopHeaderFormat:
        this.lng == 'vi' ? 'dddd, DD MMMM, YYYY' : 'dddd, MMMM DD, YYYY',
      dayHeaderFormat: 'YYYY dddd, MMMM DD',
      timeFormat: 'LT',
      dayHeader: false,
      extraFormats: false,
      minDate: false, //Accepts: date, moment, string
      maxDate: false, //Accepts: date, moment, string
      useCurrent: true, //On show, will set the picker to the current date/time
      viewMode: 'months', //Accepts: 'decades','years','months','days'
      timepicker: false,
      defaultDay: this.currentDay,
      startViewMode: 'months',
      weekStart: 0,
      weekEnd: 6,
    };
  }

  init() {
    if (this._options.useCurrent) {
      this._optiond.defaultDay;
    }
    var now = Moment();
    var date = Moment().startOf('months').startOf('dates');
    var daysOfMonth = now.daysInMonth();
    this.dateTimeData = {};
  }

  updateDate() {
    this.currentDay = Moment().utcOffset(this.timezone);
  }
  show(e) {
    e.stopPropagation();
    e.preventDefault();
    this.isVisible = true;
    this.fill(this._options.startViewMode);
    this.updateTitle();
  }

  hide() {
    if (!this.isVisible) return;
    this.isVisible = false;
    this.viewMode = this.options.startViewMode;
    this.states.viewDate = this.states.date || this.currentDay;
  }

  clickedOutside(e) {
    var target = e.target;
    if (target.className == 'fa fa-calendar') {
      target = target.parentElement;
    }
    if (
      target &&
      target.classList.contains('mde-date-btn') &&
      target.parentElement &&
      target.parentElement.id == this.id
    ) {
      this.isVisible = true;
      return true;
    }
    // Clicked outside the datetimepicker, hide it
    if (!Util.closest(e.target, '.datetimepicker')) {
      this.hide();
    }
  }

  setView(view, reset) {
    this.states.currentView = view || this.states.currentView;
    if (reset) {
      this.fill(this.states.currentView);
    }
  }

  fillDays(month) {
    this.states.monthTitle = this.states.viewDate.format('MMMM YYYY');
    var today = Moment();
    var year = this.states.viewDate.year();
    month = month || this.states.viewDate.month();
    var selectDate = this.states.date
      ? this.states.date.clone().utcOffset(this.timezone)
      : null;
    var currentDate = this.states.date
      ? Moment([
          selectDate.year(),
          selectDate.month(),
          selectDate.date(),
          0,
          0,
          0,
        ]).valueOf()
      : null;
    // prevMonth
    var prevNumberMonth = month == 0 ? 11 : month - 1;
    var prevYear = year;
    if (month == 0) {
      prevYear = prevYear - 1;
    }
    var prevMonth = Moment([prevYear, prevNumberMonth, 28, 0, 0, 0]);
    var day = prevMonth.daysInMonth();

    prevMonth.date(day);
    prevMonth.date(
      day - ((prevMonth.isoWeekday() - this._options.weekStart + 7) % 7)
    );

    // nextMonth
    var nextMonth = prevMonth.clone();
    nextMonth.add(42, 'day');
    var weekElements = [];
    var week = [];
    while (prevMonth.valueOf() < nextMonth.valueOf()) {
      var element = {
        date: prevMonth.date(),
        month: prevMonth.month(),
        class: [],
      };
      if (
        prevMonth.year() < year ||
        (prevMonth.year() === year && prevMonth.month() < month)
      ) {
        element.class.push('mde-disabled');
        element.class.push('mde-old');
      } else if (
        prevMonth.year() > year ||
        (prevMonth.year() === year && prevMonth.month() > month)
      ) {
        element.class.push('mde-new');
        element.class.push('mde-disabled');
      }
      // Compare internal UTC date with local today, not UTC today
      if (
        prevMonth.year() === today.year() &&
        prevMonth.month() === today.month() &&
        prevMonth.date() === today.date()
      ) {
        element.class.push('mde-today');
      }
      if (prevMonth.valueOf() === currentDate) {
        element.class.push('mde-active');
      }
      //            if ((prevMonth.valueOf() + 86400000) <= this.startDate || prevMonth.valueOf() > this.endDate ||
      //              $.inArray(prevMonth.getUTCDay(), this.daysOfWeekDisabled) !== -1 ||
      //              $.inArray(prevMonth.toDateString(), this.datesDisabled) !== -1) {
      //              classes.push('disabled');
      //            }
      week.push(element);
      var weekNumber = prevMonth.week();
      prevMonth.add(1, 'day');
      if (week.length == 7) {
        weekElements.push(week);
        week = [];
      }
    }
    this.states.weekElements = weekElements;
  }

  fillMonths() {
    this.states.yearTitle = this.states.viewDate.year();
    var currentDate = this.states.date;
    var viewDate = this.states.viewDate;
    var monthElements = [];
    for (var i = 0; i < 12; i++) {
      var element = {
        month: this.localeData._monthsShort[i],
        class: [],
      };

      if (
        currentDate.format('MMM') === this.localeData._monthsShort[i] &&
        currentDate &&
        currentDate.year() == viewDate.year()
      ) {
        element.class.push('mde-active');
      }
      monthElements.push(element);
    }

    this.states.monthElements = monthElements;
  }

  fillYears(year) {
    var d = this.states.viewDate,
      year = year || d.year();

    year = parseInt(year / 10, 10) * 10;

    var yearCont = [];
    year -= 1;
    for (var i = -1; i < 11; i++) {
      let yearElement = {
        year: year,
        class: [],
      };
      if (i === -1 || i === 10) {
        yearElement.class.push('mde-old');
      }
      if (year == this.states.date.year()) {
        yearElement.class.push('mde-active');
      }
      yearCont.push(yearElement);
      year += 1;
    }
    this.states.decadesTitle = `${yearCont[0].year}-${
      yearCont[yearCont.length - 1].year
    }`;
    this.states.yearElements = yearCont;
  }

  fill(view) {
    switch (view) {
      case 'decades':
        this.fillYears();
        break;
      case 'years':
        this.fillMonths();
        break;
      case 'months':
        this.fillDays();
        break;
    }
  }

  loadNext(view) {
    view = view || this.states.viewMode;
    switch (view) {
      case 'decades':
        this.states.viewDate.add(-12, 'year');
        break;
      case 'years':
        this.states.viewDate.add(1, 'years');
        break;
      case 'months':
        this.states.viewDate.add(1, 'months');
        break;
    }
    this.fill(view);
  }

  loadPrev(view) {
    view = view || this.states.viewMode;
    switch (view) {
      case 'decades':
        this.states.viewDate.add(-12, 'year');
        break;
      case 'years':
        this.states.viewDate.add(-1, 'year');
        break;
      case 'months':
        this.states.viewDate.add(-1, 'month');
        break;
    }
    this.fill(view);
  }

  prevTime() {
    var value = -1;
    if (this.states.viewDate.minute() > 0) {
      this.states.viewDate.add(value, 'minute');
      this.states.date = this.states.date || this.states.viewDate.clone();
      this.states.date.minute(this.states.viewDate.minute());
      this.value = this.datetimeConverter.toView(
        this.states.date,
        this.options.timepicker === false ? false : true
      );
      this.updateTitle();
    }
  }

  nextTime() {
    if (this.states.viewDate.minute() < 60) {
      var value = 1;
      this.states.viewDate.add(value, 'minute');
      this.states.date = this.states.date || this.states.viewDate.clone();
      this.states.date.minute(this.states.viewDate.minute());
      this.value = this.datetimeConverter.toView(
        this.states.date,
        this.options.timepicker === false ? false : true
      );
      this.updateTitle();
    }
  }

  selectMeridiem(value) {
    if (value != this.states.meridiem) {
      if (value == 'AM') {
        if (this.states.viewDate.hour() > 12)
          this.states.viewDate.add(-12, 'hour');
      } else {
        this.states.viewDate.add(12, 'hour');
      }
      this.states.date = this.states.date || this.states.viewDate.clone();
      this.states.date.hour(this.states.viewDate.hour());
      this.value = this.datetimeConverter.toView(
        this.states.date,
        this.options.timepicker
      );
      this.updateTitle();
    }
  }
  select(evt, input, type) {
    evt.stopPropagation();
    evt.preventDefault();
    var value = 0;
    switch (type) {
      case 'year':
        value = input;
        this.states.viewDate.set(type, value);
        this.setView('years', true);
        break;
      case 'month':
        value = input;
        this.states.viewDate.set(type, value);
        this.setView('months', true);
        break;
      case 'date':
        value = input.date;
        this.states.viewDate.set(type, value).set('month', input.month);
        setTimeout(() => {
          this.fillDays();
        }, 50);

        if (this._options.timePicker) {
          this.showPicker = true;
        } else {
          // this.isVisible = false;
        }

        break;
      case 'hour':
        var value = input;
        if (this.states.meridiem == 'PM') {
          value = value + 12;
        }
        this.states.viewDate.set(type, value);
        //                this.isVisible = false;
        break;
    }

    this.states.date = this.states.date || this.states.viewDate.clone();
    this.states.date
      .year(this.states.viewDate.year())
      .month(this.states.viewDate.month())
      .date(this.states.viewDate.date())
      .hour(this.states.viewDate.hour())
      .minute(this.states.viewDate.minute());
    this.value = this.datetimeConverter.toView(
      this.states.date,
      this._options.timepicker
    );
    this.updateTitle();
  }

  updateTitle() {
    if (this.states.date) {
      this.valueTitle = this.datetimeConverter.toView(
        this.states.date,
        this._options.timepicker
      ); //this.states.date.format(this._options.format);
      this.states.headerYearTitle = this.states.date.year();
      this.states.headerMonthTitle = this.states.date.format('ddd, MMM DD');
      this.states.headerTimeTitle = this.states.date.format('HH: mm A');
      this.states.hour = this.states.date.format('h');
      this.states.minute = this.states.date.minute();
      this.states.meridiem = this.states.date.format('A');
    }
  }
  showTimePicker() {
    this.showPicker = true;
  }

  hideTimePicker() {
    this.showPicker = false;
  }

  setToDay() {
    this.states.date = Moment().utcOffset(this.timezone);
    this.states.viewDate = this.states.date.clone();
    this.states.date
      .year(this.states.viewDate.year())
      .month(this.states.viewDate.month())
      .date(this.states.viewDate.date());
    this.value = this.datetimeConverter.toView(
      this.states.date,
      this.options.timepicker
    );
  }

  WidthJS() {
    let findName = '.mde-text-wrapper';
    let findNameInput = findName + ' input';
    if (
      !this.element.querySelector('.mde-add-maxWidth') ||
      !this.element.querySelector('.mde-getWidth') ||
      !this.element.querySelector(findName)
    ) {
      return;
    }
    var that = this.element;
    var timeOut = 250;
    if (!this.element.querySelector('.mde-results-content')) {
      timeOut = 600;
    } else {
      let getItemFirst = this.element.querySelector(
        '.mde-results-content'
      ).children;
      if (getItemFirst.length == 0) {
        timeOut = 600;
      }
    }

    setTimeout(function () {
      let getItemSecond = that.querySelector(findNameInput).value;
      if (getItemSecond.split(' ', 3).length < 2) {
        getItemSecond = that.querySelector(findNameInput).placeholder;
      }
      that.querySelector('.mde-getWidth .mde-getWidthContent').innerHTML =
        getItemSecond;
      var getWidth = that.querySelector(
        '.mde-getWidth .mde-getWidthContent'
      ).offsetWidth;
      that.querySelector(findName).style.width = 100 + '%';
      if (getWidth < 100) {
        that.querySelector(findName).style.maxWidth = 150 + 'px';
      } else {
        that.querySelector(findName).style.maxWidth = getWidth + 60 + 'px';
      }
    }, timeOut);
  }
}

export class LocalDatetimeFormatValueConverter {
  constructor() {
    this.lng = State.get('me.language', 'en');
    this.format =
      this.lng == 'vi' ? 'dddd, DD MMMM, YYYY' : 'dddd, MMMM DD, YYYY h:mm A';
  }

  toView(value, format) {
    format = format || this.format;
    return Moment(value).format(format);
  }
}
