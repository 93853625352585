import {customElement, inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

import _ from 'lodash';
import Moment from 'moment';
import {Events} from 'lib/enum';

import * as Notifier from 'lib/notifier';

@customElement('mde-message')
@inject(Element, EventAggregator)
export class MdeMessage {
  // init variables
  mdeShow = false;
  mdeType = 'mde-info';
  messages = [];

  constructor(element, event) {
    this.element = element;
    this.event = event;
  }

  attached() {
    this.interval = setInterval(() => {
      this.messages = _.filter(
        this.messages,
        (message) => message.expiry > Date.now()
      );
    }, 500);

    this.subscriptions = [
      this.event.subscribe(Events.showMessage, (payload) =>
        this.handle(payload)
      ),
      Notifier.event.subscribe(Events.showMessage, (payload) =>
        this.handle(payload)
      ),
    ];
  }

  /**
   * unbind t attribute
   * @author : thanhdh
   */
  detached() {
    _.each(this.subscriptions, (sub) => sub.dispose());

    this.subscriptions = [];

    clearInterval(this.interval);
  }

  handle(payload) {
    const message = {
      id: Date.now() + (Math.random() * Date.now()).toFixed(0),
      content: payload.message || '',
      type: payload.type,
      isShown: false,
      expiry: Moment()
        .add(payload.timeout || 5, 'second')
        .valueOf(),
    };

    this.messages.push(message);

    setTimeout(() => {
      message.isShown = true;
    }, 50);
  }

  close(message) {
    this.messages = _.reject(this.messages, {id: message.id});
  }
}
