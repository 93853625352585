import {inject, customAttribute} from 'aurelia-framework';

@customAttribute('show')
@inject(Element)
export class Show {
  constructor(element) {
    this.element = element;
  }

  valueChanged(newValue, oldValue) {
    if (newValue) {
      this.element.classList.remove('mde-hidden');
    } else {
      this.element.classList.add('mde-hidden');
    }
  }
}
