import {inject, customElement, bindable} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';

import {debug} from 'lib/logger';

import Notifier from 'lib/notifier';
import Locale from 'lib/locale';
import State from 'lib/state';

import * as Util from 'lib/util';
import * as CommonService from 'services/common.v2';

@customElement('mde-dropdown-event')
@inject(Element, BindingSignaler)
export class MdeDropdownEvent {
  //changable
  @bindable isAutoInitial = true;
  @bindable dataSelected;
  @bindable dataSources = [];
  @bindable isReset;
  //one time
  @bindable sourceConfig = {};
  @bindable selectedConfig = {};
  @bindable dataMap = {};

  @bindable isDisabled = false;
  @bindable placeholder = '';
  @bindable mdeStyle = '';
  @bindable mdeClass = '';
  constructor(element, signaler) {
    this.element = element;
    this.signaler = signaler;

    let self = this;

    this.mhelper = new Helper();
    this.minput = new Input(this.mhelper, signaler);
    this.msource = new Source(this.mhelper);
    this.mselected = new Selected(this.mhelper);

    this.display = {
      get sources() {
        return self.msource.data;
      },
      get selected() {
        return self.mselected.data;
      },
      get input() {
        return self.minput;
      },
      is_showing_options: false,
    };

    this.name =
      this.constructor.name.padEnd(30, '-') +
      ' ' +
      Math.random().toString().slice(-3);
  }

  attached() {
    this.minput.startInterval();
    this.WidthJS();
  }

  detached() {
    this.minput.clearInterval();
  }

  onReceiveData(event) {
    let data = event.detail || {};
    switch (data.topic) {
      case 'reset':
      case 'init':
        return this.initData();
      case 'apply':
        this.eventSet(data.set);
        return this.initData();
    }
  }

  eventSet(data) {
    let accept = {
      sourceConfigParams: true,
      selectedConfigParams: true,
      dataSelected: true,
    };
    for (var i in data) {
      if (accept[i]) {
        this[i] = data[i];
      }
    }
  }

  bind() {
    this.mhelper.init(this.dataMap, this.selectedConfig, this.sourceConfig);

    this.minput.bindFunctions({
      onFocusChanged: (value) => this.onFocusChanged(value),
      onValueChanged: (value) => this.onValueChanged(value),
      onClearValue: () => this.onClearValue(),
      onResetValue: () => this.onResetValue(),
    });

    this.mselected.bindFunctions({
      onValueChanged: () => this.selectedChanged(),
    });

    this.isAutoInitial && this.initData();
    this.WidthJS();
  }

  dataSelectedChanged() {
    this.isAutoInitial && this.initData('selectedChanged');
    this.WidthJS();
  }

  isResetChanged() {
    if (this.isReset) {
      return this.initData();
    }
  }

  dataSourcesChanged() {
    this.isAutoInitial && this.initData();
  }

  initData(name) {
    let dataSources = this.mhelper.clearDuplicated(this.dataSources || []),
      dataSelected = this.mhelper.getFrom(this.dataSelected);

    debug(this.name, 'Init data', dataSelected, dataSources);
    if (name == 'selectedChanged' && !this.mselected.isDiff(dataSelected)) {
      return;
    }
    this.fireDataSelected = false;

    this.mhelper.setSourceConfigParams(this.sourceConfigParams);
    this.mhelper.setSelectedConfigParams(this.selectedConfigParams);

    this.msource.init(dataSelected, dataSources);
    this.mselected.init(dataSelected, dataSources, (err, found) => {
      if (err || (!found && this.mhelper.config.selected.cleanable)) {
        return this.clearValue();
      }
      this.minput.setText(this.mselected.getText());
      if (!this.fireDataSelected) {
        this.fireDataSelected = true;
        Util.fireEvent(this.element, this.mselected.getData(), 'data-selected');
      }
    });
    this.isReadOnly = !Object.keys(this.sourceConfig.filter || {}).length;
  }

  selectedChanged() {
    debug(this.name, 'Event on data changed');

    this.minput.setText(this.mselected.getText());

    Util.fireEvent(this.element, this.mselected.getData());
  }

  onSelectedOption(option) {
    debug(this.name, 'Event on selected', option);
    let selected = this.mselected.selectOption(option);
    if (selected) {
      this.msource.filterSources({
        exclusion: option.value,
      });
    }
    this.display.is_showing_options = false;
    this.minput.setFocus(false);
  }

  onFocusChanged(value) {
    let hintable = this.mhelper.config.source.hintable,
      is_disabled = this.isDisabled,
      is_focused = value;

    let is_show = value && !is_disabled && hintable;
    this.display.is_showing_options = is_show;

    !value && this.minput.setText(this.mselected.getText());
  }

  onValueChanged(value) {
    this.msource.filterSources(
      {
        text: value,
      },
      'filter'
    );
  }

  onClearValue() {
    if (this.isDisabled) {
      return;
    }
    if (!this.mhelper.config.selected.clearable) {
      return;
    }
    debug(this.name, 'Event on removed selected');
    this.clearValue();
  }

  clearValue() {
    let removed = this.mselected.removeSelected();
    if (removed) {
      this.msource.filterSources({
        inclusion: removed.value,
      });
    }
    this.minput.userChangedText('');
  }

  onResetValue() {
    debug(this.name, 'Event on reset to selected');
    this.minput.setText(this.mselected.getText());
    this.display.is_showing_options = false;
    this.minput.setFocus(false);
  }

  WidthJS() {
    let findName = '.mde-drop-down-wrapper';
    let findNameInput = findName + ' input';
    if (
      !this.element.querySelector('.mde-add-maxWidth') ||
      !this.element.querySelector('.mde-getWidth') ||
      !this.element.querySelector(findName)
    ) {
      return;
    }
    var that = this.element;
    var timeOut = 600;
    if (!this.element.querySelector('.mde-results-content')) {
      timeOut = 600;
    } else {
      let getItemFirst = this.element.querySelector(
        '.mde-results-content'
      ).children;
      if (getItemFirst.length == 0) {
        timeOut = 1000;
      }
    }

    setTimeout(function () {
      let getItemSecond = that.querySelector(findNameInput).value;
      if (getItemSecond.split(' ', 3).length < 2) {
        getItemSecond = that.querySelector(findNameInput).placeholder;
      }
      that.querySelector('.mde-getWidth .mde-getWidthContent').innerHTML =
        getItemSecond;
      var getWidth = that.querySelector(
        '.mde-getWidth .mde-getWidthContent'
      ).offsetWidth;
      that.querySelector(findName).style.width = 100 + '%';
      if (getWidth < 100) {
        that.querySelector(findName).style.maxWidth = 150 + 'px';
      } else {
        that.querySelector(findName).style.maxWidth = getWidth + 60 + 'px';
      }
    }, timeOut);
  }
}

class Source {
  //contain data
  constructor(helper) {
    this.helper = helper;

    this.data = [];
    this.name = 'source';
    this.log_name =
      this.constructor.name.padEnd(30, '-') +
      ' ' +
      Math.random().toString().substr(-3);
  }

  mapConfig() {
    let config = {
      type: 'local',
    };
    if (this.helper.config.source.request.url) {
      config = {
        type: 'url',
        method: this.helper.config.source.request.method,
      };
    }
    return config;
  }

  init(exclusion, options) {
    this.config = this.mapConfig();
    this.filter = {
      text: '',
      exclusions: {},
    };
    this.data.splice(0);

    if (exclusion) {
      this.filter.exclusions[exclusion.value] = true;
    }

    if (this.config.type == 'local') {
      this.buildLocalSources(options || []);
    } else if (this.config.type == 'url') {
      this.filterRemoteSources();
    }
  }

  buildLocalSources(sources) {
    this.localSource = sources;
    this.filterLocalSources();
  }

  filterLocalSources() {
    this.mergeData(
      this.localSource.filter((item) => {
        let is_filter_text = true;
        if (this.filter.text !== undefined) {
          is_filter_text =
            item.text.toLowerCase().includes(this.filter.text) ||
            (item.value || '')
              .toString()
              .toLowerCase()
              .includes(this.filter.text);
        }
        return is_filter_text && !this.filter.exclusions[item.value];
      })
    );
  }

  filterRemoteSources(next) {
    this.fetchDataSources(next);
  }

  filterSources(filter = {}, name) {
    if (filter.exclusion) {
      //            let index = this.data.findIndex(item => item.value == filter.exclusion);
      //            if (index != -1) {
      //                this.data.splice(index, 1);
      //            }
      this.filter.exclusions = {};
      this.filter.exclusions[filter.exclusion] = true;
    }
    if (filter.inclusion) {
      delete this.filter.exclusions[filter.inclusion];
    }
    if (filter.exclusions !== undefined) {
      this.filter.exclusions = filter.exclusions;
    }
    if (filter.text !== undefined) {
      this.filter.text = filter.text.toLowerCase();
    }
    if (this.config.type == 'local') {
      this.filterLocalSources();
    } else if (this.config.type == 'url') {
      if (name != 'filter' || this.helper.config.source.request.filter.q) {
        this.filterRemoteSources();
      }
    }
  }

  mergeData(rows) {
    this.data.splice(0);
    rows.forEach((item) => {
      this.data.push(item);
    });
    //        for (let i = 0; i < this.data.length; i++) {
    //            Object.assign(this.data[i], rows[i])
    //        }
    //        for (let i = 0; i < rows.length; i++) {
    //            this.data.push(rows[i]);
    //        }
  }

  fetchDataSources(next) {
    let method = this.config.method,
      url = this.helper.getSourceRequestQuery(this.filter),
      res;
    if (method == 'get') {
      res = CommonService.GET(url);
    } else {
      res = CommonService.FETCH(url, {
        data: this.helper.getSourceRequestData(this.filter),
        method,
      });
    }

    res.then(
      (res) => {
        if (res.errors) {
          return next && next(res.errors);
        }
        this.mergeData(
          this.helper.clearDuplicated(
            this.helper.getResponseData(res, this.name)
          )
        );
        next && next();
      },
      (err) => {
        next && next(err);
      }
    );
  }
}

class Selected {
  constructor(helper, service) {
    this.helper = helper;

    this.name = 'selected';
    this.noSelected = Symbol('no-selected');
    this.data = this.noSelected;

    this.events = {
      listeners: {},
      get onValueChanged() {
        return this.listeners.onValueChanged || function () {};
      },
    };
  }

  mapConfig() {
    let config = {
      type: 'local',
    };
    if (this.helper.config.selected.request.url) {
      config = {
        type: 'url',
        method: this.helper.config.selected.request.method,
      };
    }
    return config;
  }

  init(selected, sources, next) {
    this.config = this.mapConfig();
    this.filter = {
      exclusions: {},
    };
    this.data = this.noSelected;
    this.initDefaultSelected(selected, sources, next);
  }

  bindFunctions(listeners) {
    Object.assign(this.events.listeners, listeners);
  }

  isDiff(selected) {
    return !selected != !this.data || selected.value != this.data.value;
  }

  selectOption(option) {
    if (this.filter.exclusions[option.value]) {
      return;
    }
    let selected = this.select(option);
    if (selected) {
      this.events.onValueChanged();
    }
    return selected;
  }

  select(option) {
    if (this.filter.exclusions[option.value]) {
      return;
    }
    this.filter.exclusions = {};
    this.data = option;
    this.filter.exclusions[option.value] = true;
    return option;
  }

  removeSelected(option) {
    if (this.data == this.noSelected) {
      return;
    }
    let removed = this.data;
    delete this.filter.exclusions[removed.value];
    this.data = this.noSelected;
    this.events.onValueChanged();
    return removed;
  }

  getData() {
    return this.data ? this.helper.rebuildFrom(this.data) : null;
  }

  getText() {
    if (this.data) {
      return this.data.text;
    }
    return '';
  }

  initDefaultSelected(selected, sources, next) {
    if (selected === undefined || selected === null) {
      return next();
    }
    this.select(selected);
    if (this.config.type == 'local') {
      this.loadDataFromSource(sources, next);
    } else if (this.config.type == 'url') {
      this.fetchSelectedText(next);
    }
  }

  loadDataFromSource(sources, next) {
    let found = sources.some((option) => {
      if (option.value == this.data.value) {
        this.data.text = option.text;
        this.data.origin = option.origin;
        return true;
      }
    });
    next && next(null, found);
  }

  fetchSelectedText(next) {
    let method = this.config.method,
      url = this.helper.getSelectedRequestQuery(this.filter),
      res;

    if (method == 'get') {
      res = CommonService.GET(url);
    } else {
      res = CommonService.FETCH(url, {
        data: this.helper.getSelectedRequestData(this.filter),
        method,
      });
    }

    res.then(
      (res) => {
        if (res.errors) {
          return next && next(res.errors);
        }
        this.loadDataFromSource(
          this.helper.clearDuplicated(
            this.helper.getResponseData(res, this.name)
          ),
          next
        );
      },
      (err) => {
        next && next(err);
      }
    );
  }
}

//manage processing data
class Helper {
  constructor() {
    this._p = Symbol('processed');

    this.listeners = {
      config: {},
    };
  }

  mapConfig(config, selectedConfig, sourceConfig, options) {
    Object.assign(selectedConfig, {
      url: selectedConfig.url || '',
      method: selectedConfig.method || '',
      path: selectedConfig.path || '',
      params: options.selectedConfigParams || {},
      exclusion: selectedConfig.exclusion || {},
      clearable: selectedConfig.clearable !== false,
      cleanable: selectedConfig.cleanable || false,
    });
    Object.assign(sourceConfig, {
      url: sourceConfig.url || '',
      method: sourceConfig.method || '',
      path: sourceConfig.path || '',
      limit: sourceConfig.limit || {},
      filter: sourceConfig.filter || {},
      params: options.sourceConfigParams || {},
      exclusion: sourceConfig.exclusion || {},
      hintable: sourceConfig.hintable !== false,
    });
    return {
      data: {
        id: config.id || 'value',
        text: config.text || 'text',
        sortCol: config.sortCol || '',
        sortOrder: config.sortOrder || '',
      },
      selected: {
        clearable: selectedConfig.clearable,
        cleanable: selectedConfig.cleanable,
        request: {
          url: selectedConfig.url,
          method: selectedConfig.method.trim().toLowerCase() || 'get',
          exclusion: {
            q: selectedConfig.exclusion.query || '',
            type: selectedConfig.exclusion.type || 'params',
          },
          params: selectedConfig.params || {},
        },
        response: {
          paths: selectedConfig.path ? selectedConfig.path.split('.') : [],
          formatter: selectedConfig.formatter || _.identity,
        },
      },
      source: {
        hintable: sourceConfig.hintable,
        request: {
          url: sourceConfig.url,
          method: sourceConfig.method.trim().toLowerCase() || 'get',
          limit: {
            q: sourceConfig.limit.query || '',
            total: sourceConfig.limit.total || undefined,
            type: sourceConfig.limit.type || 'params',
          },
          filter: {
            q: sourceConfig.filter.query || '',
            type: sourceConfig.filter.type || 'params',
          },
          exclusion: {
            q: sourceConfig.exclusion.query || '',
            type: sourceConfig.exclusion.type || 'params',
          },
          params: sourceConfig.params || {},
        },
        response: {
          paths: sourceConfig.path ? sourceConfig.path.split('.') : [],
          formatter: sourceConfig.formatter || _.identity,
        },
      },
    };
  }

  init(config, selectedConfig, sourceConfig, options = {}) {
    this.config = this.mapConfig(
      config || {},
      selectedConfig || {},
      sourceConfig || {},
      options
    );

    for (let i in this.listeners.config) {
      this.listeners.config[i]();
    }
  }

  onConfigChanged(cl, fnc) {
    this.listeners.config[cl.constructor.name] = fnc;
  }

  setSourceConfigParams(configParams) {
    this.config.source.request.params = configParams;
  }

  setSelectedConfigParams(configParams) {
    this.config.selected.request.params = configParams;
  }

  getValue(option) {
    if (typeof option != 'object') {
      return option;
    }
    if (option[this._p]) {
      return option.value;
    }
    return option[this.config.data.id];
  }

  getValues(data) {
    return data.map((item) => this.getFrom(item).value);
  }

  getTranslatedText(text) {
    if (!text) {
      return;
    }
    return Locale.translate(text);
  }

  getText(option) {
    if (typeof option != 'object') {
      return option || '';
    }
    if (option[this._p]) {
      return option.text;
    }
    let text = option[this.config.data.text] || '';

    if (option.is_translated === false) {
      return this.getTranslatedText(text);
    }
    return text;
  }

  getSortCol(option) {
    if (typeof option != 'object') {
      return null;
    }
    if (option[this._p]) {
      return option.sortCol;
    }
    return this.config.data.sortCol ? option[this.config.data.sortCol] : null;
  }

  getFrom(option) {
    if (option === undefined || option === null) {
      return;
    }
    if (typeof option == 'object' && Object.keys(option).length == 0) {
      return;
    }
    if (typeof option == 'object' && option[this._p]) {
      return option;
    }
    let data = {
      text: this.getText(option),
      value: this.getValue(option),
      sortCol: this.getSortCol(option),
      origin: option,
    };
    data[this._p] = true;
    return data;
  }

  rebuildFrom(option) {
    let data = {
      origin: option.origin,
    };
    data[this.config.data.id] = option.value;
    if (this.config.data.id != this.config.data.text) {
      data[this.config.data.text] = option.text;
    }
    return data;
  }

  getRequestLimitData() {
    let q = this.config.source.request.limit.q,
      total = this.config.source.request.limit.total,
      type = this.config.source.request.limit.type,
      data = {};
    if (type != 'data' || !q || total === undefined) {
      return data;
    }
    data[q] = this.config.source.request.limit.total || 0;
    return data;
  }

  getRequestExclusionData(exclusions, name) {
    let q = this.config[name].request.exclusion.q,
      type = this.config[name].request.exclusion.type,
      data = {};
    if (type != 'data' || !q || !exclusions.length) {
      return data;
    }
    data[q] = exclusions;
    return data;
  }

  getRequestFilterData(text) {
    let q = this.config.source.request.filter.q,
      type = this.config.source.request.filter.type,
      data = {};
    if (type != 'data' || !q || !text) {
      return data;
    }
    data[q] = text;
    return data;
  }

  getRequestLimitQuery() {
    let q = this.config.source.request.limit.q,
      total = this.config.source.request.limit.total,
      type = this.config.source.request.limit.type;
    if (type != 'params' || !q || total === undefined) {
      return '';
    }
    return q + '=' + (this.config.source.request.limit.total || 0);
  }

  getRequestExclusionQuery(exclusions, name) {
    let q = this.config[name].request.exclusion.q,
      type = this.config[name].request.exclusion.type;
    if (type != 'params' || !q || !exclusions.length) {
      return '';
    }
    return (
      encodeURIComponent(q) +
      '=' +
      exclusions.map((item) => encodeURIComponent(item)).join('&' + q + '=')
    );
  }

  getRequestFilterQuery(text) {
    let q = this.config.source.request.filter.q,
      type = this.config.source.request.filter.type;

    if (type != 'params' || !q || !text) {
      return '';
    }
    return encodeURIComponent(q) + '=' + encodeURIComponent(text);
  }

  getRequestParamsQuery(name) {
    let params = this.config[name].request.params || {},
      res = [];
    for (let i in params) {
      if (params[i] == undefined) {
        continue;
      }
      res.push(encodeURIComponent(i) + '=' + encodeURIComponent(params[i]));
    }
    return res;
  }

  getSourceRequestQuery(filter = {}) {
    let name = 'source',
      url = (this.config[name].request.url || '').trim(),
      op = url.includes('?') ? '&' : '?',
      limitq = this.getRequestLimitQuery(),
      filterq = this.getRequestFilterQuery(filter.text),
      exclusionsq = this.getRequestExclusionQuery(
        Object.keys(filter.exclusions || {}),
        name
      ),
      otherparams = this.getRequestParamsQuery(name);
    if (!url) {
      return '';
    }

    return `${url}${op}${[limitq, filterq, exclusionsq, ...otherparams]
      .filter((item) => item)
      .join('&')}`;
  }

  getSourceRequestData(filter = {}) {
    let name = 'source';
    return Object.assign(
      {},
      this.getRequestLimitData(),
      this.getRequestFilterData(filter.text),
      this.getRequestExclusionData(Object.keys(filter.exclusions || {}), name)
    );
  }

  getSelectedRequestQuery(filter = {}) {
    let name = 'selected',
      url = (this.config[name].request.url || '').trim(),
      op = url.includes('?') ? '&' : '?',
      exclusionsq = this.getRequestExclusionQuery(
        Object.keys(filter.exclusions || {}),
        name
      ),
      otherparams = this.getRequestParamsQuery(name);
    if (!url) {
      return '';
    }

    return `${url}${op}${[exclusionsq, ...otherparams].join('&')}`;
  }

  getSelectedRequestData(filter = {}) {
    let name = 'selected';
    return Object.assign(
      {},
      this.getRequestExclusionData(Object.keys(filter.exclusions || {}), name)
    );
  }

  validate(value) {
    let regex = null;
    if (this.config.insert.type == 'email') {
      regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    } else if (this.config.insert.regex.value) {
      regex = new RegExp(
        this.config.insert.regex.value,
        this.config.insert.regex.flag
      );
    } else {
      return true;
    }
    return regex.test(value);
  }

  getResponseData(res, name = 'source') {
    let data;
    if (Array.isArray(res)) {
      data = res;
    } else {
      data = [res];
    }
    this.config[name].response.paths.forEach((path) => {
      if (data !== undefined) {
        data = data[path];
      }
    });

    const formatter = this.config[name].response.formatter;

    return _.map(data, formatter);
  }

  clearDuplicated(rows) {
    let data = [],
      marker = {};
    rows.forEach((row) => {
      let pdata = this.getFrom(row);
      if (!pdata || marker[pdata.value]) {
        return;
      }
      data.push(pdata);
      marker[pdata.value] = true;
    });
    return data;
  }
}

class Input {
  //manage input
  constructor(helper, signaler) {
    this.helper = helper;
    this.signaler = signaler;
    this.element = null;

    this.config = {};

    this.userLog = {
      _pressing: false,
      is_end: true,
      last_texted: 0,
      text: '',

      set pressing(val) {
        this._pressing = val;
        if (!val) {
          this.is_end = false;
          this.last_texted = Date.now();
        }
      },
      get pressing() {
        return this._pressing;
      },
    };

    this.focusLog = {
      _focusing: false,
      input_focusing: false,
      last_focused: 0,
      set focusing(val) {
        this._focusing = val;
        this.last_focused = Date.now();
      },
      get focusing() {
        return this.input_focusing || this._focusing;
      },
    };

    this.events = {
      listeners: {},
      get onFocusChanged() {
        return this.listeners.onFocusChanged || function () {};
      },
      get onValueChanged() {
        return this.listeners.onValueChanged || function () {};
      },
      get onInsertData() {
        return this.listeners.onInsertData || function () {};
      },
      get onClearValue() {
        return this.listeners.onClearValue || function () {};
      },
      get onResetValue() {
        return this.listeners.onResetValue || function () {};
      },
    };

    this.validators = {
      validators: {},
      get validateInsertData() {
        return (
          this.validators.validateInsertData ||
          function () {
            return false;
          }
        );
      },
    };

    this.focusState = false;
    this.last_text_value = '';

    this.helper.onConfigChanged(this, () => {
      this.config = this.helper.config.selected;
    });
  }

  bindFunctions(listeners, validators) {
    Object.assign(this.events.listeners, listeners);
    Object.assign(this.validators.validators, validators);
  }

  setFocus(val) {
    this.focusLog.input_focusing = val !== false;
  }

  setText(value) {
    this.userLog.text = value;
    this.text = value;
    this.signaler.signal('input-value-updated');
  }

  eventTextChanged(to, from, options) {
    if (options.name == 'enter' && this.validators.validateInsertData(to)) {
      debug('Event tag inserted', to);
      return this.events.onInsertData(to);
    }

    if (options.name == 'button' && !to) {
      debug('Event button clear', to);
      return this.events.onClearValue();
    }

    if (options.name == 'escape' && !to) {
      debug('Event escape', to);
      return this.events.onResetValue();
    }

    if (from != to) {
      debug('Event tag text changed', to);
      this.events.onValueChanged(to);
      this.last_text_value = to;
    }
  }

  eventFocusChanged(value) {
    if (this.focusState == value) {
      return;
    }
    this.focusState = value;
    debug('Event tag focus changed', value);
    this.events.onFocusChanged(value);
  }

  userChangedFocus(value) {
    if (value == this.focusState) {
      return;
    }
    this.eventFocusChanged(value);
  }

  focusedIntervalJob() {
    if (!this.focusState || this.focusLog.focusing) {
      return;
    }
    if (Date.now() - this.focusLog.last_focused > 200) {
      this.userChangedFocus(false);
    }
  }

  userChangedText(text, options = {}) {
    let from = this.last_text_value,
      to = text === null ? this.userLog.text : text;

    this.userLog.is_end = true;
    if (text !== null) {
      this.setText(to);
    }
    this.eventTextChanged(to, from, options);
  }

  textedIntervalJob() {
    if (this.userLog.is_end || this.userLog.pressing) {
      return;
    }
    if (Date.now() - this.userLog.last_texted > 300) {
      this.userChangedText(null, {
        name: 'interval',
      });
    }
  }

  startInterval() {
    this.interval100ms = setInterval(() => {
      this.focusedIntervalJob();
      this.textedIntervalJob();
    }, 100);
  }
  clearInterval() {
    clearInterval(this.interval100ms);
  }

  onFocusChanged() {
    let val = document.activeElement == this.element;
    if (val) {
      this.userChangedFocus(true);
    }
    this.focusLog.focusing = val;
  }

  onClearText() {
    if (this.text || this.userLog.text) {
      this.userChangedText('', {
        name: 'button',
      });
    }
    this.setFocus();
  }

  onKeyUp(event) {
    this.userLog.text = event.target.value;
    this.userLog.pressing = false;
    let keycode = event.which || event.keyCode;
    if (keycode == 27) {
      //escape
      return this.userChangedText('', {
        name: 'escape',
      });
    } else if (keycode == 13) {
      //enter
      return this.userChangedText(null, {
        name: 'enter',
      });
    }
  }

  onKeyDown(event) {
    this.userLog.pressing = true;
    return true;
  }

  onChanged(event) {
    event.stopImmediatePropagation();
  }
}
