import _ from 'lodash';
import {inject, customElement, bindable, bindingMode} from 'aurelia-framework';

import * as CommonService from 'services/common.v2';

@customElement('select-country')
export class SelectCountry {
  @bindable id = `select-country-${
    Date.now() + (Math.random() * Date.now()).toFixed(0)
  }`;

  @bindable({defaultBindingMode: bindingMode.twoWay}) country = 'VN';
  @bindable mdeStyle = 'width: 100%';
  selectedCountry = 'VN';

  constructor() {}

  countryChanged() {
    if (this.selectedCountry !== this.country) {
      this.selectedCountry = this.country;
    }
  }

  async attached() {
    await this.init();
  }

  async init() {
    const countries = await CommonService.GET('country');

    this.countries = countries.map((country) => ({
      _id: country._id,
      name: country.text,
    }));

    this.selectedCountry = this.country;
  }

  select(event) {
    const value = _.get(event, 'detail.value._id');

    if (value !== this.country) {
      this.country = value;
    }
  }
}
