import _ from 'lodash';
import Locale from 'lib/locale';

export class TValueConverter {
  constructor() {}

  toView(value, params) {
    if (!value) {
      return value;
    }

    var result = Locale.translate(value);
    if (!_.isObject(params)) {
      return result;
    }
    var keys = _.keys(params);

    keys.forEach((key) => {
      var regx = new RegExp(`{{${key}}}`, 'g');
      result = result.replace(regx, params[key]);
    });
    return result;
  }
}
