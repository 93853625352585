'use strict';
//
//  mde-slider.js
//  slider control
//
//  Created by thanhdh on 2016-06-16.
//  Copyright 2015 Fireflyinnov. All rights reserved.
//
import {customElement, inject, bindable, bindingMode} from 'aurelia-framework';
import * as Util from 'lib/util';

@customElement('mde-slider')
@inject(Element)
export class MdeSlider {
  @bindable min = 0;
  @bindable max = 100;
  @bindable({defaultBindingMode: bindingMode.twoWay}) value = 0;
  @bindable mdeStyle = '';

  constructor(element) {
    this.element = element;
  }
  attached() {
    if (!this.value && this.value !== 0) {
      this.value = this.min;
      return;
    }
    if (this.value) {
      if (this.value <= this.min) {
        this.value = this.min;
        return;
      } else if (this.value >= this.max) {
        this.value = this.max;
        return;
      } else {
        this.value = this.value;
        return;
      }
    }
  }

  valueChanged() {
    if (this.valueTimeout) {
      clearTimeout(this.valueTimeout);
    }
    this.valueTimeout = setTimeout(() => {
      Util.fireEvent(this.element, this.value);
    }, 250);
  }
}
