export const Events = {
  showMessage: 'showMessage',
  langChanged: 'langChanged',
};

export const SubmissionCustomFields = {reason: 'reason', location: 'location'};
export const Extensions = [
  '.zip',
  '.rar',
  '.gz',
  '.tar',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.ppt',
  '.pptx',
  '.pdf',
  '.txt',
  '.mp3',
  '.m4a',
  '.wav',
];

export const ImageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];

export default {
  Events,
  SubmissionCustomFields,
  Extensions,
  ImageExtensions,
};
