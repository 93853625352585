import _ from 'lodash';
import Bluebird from 'bluebird';

import Config from 'lib/config';
import Locale from 'lib/locale';
import State from 'lib/state';
import {SubmissionCustomFields, Extensions, ImageExtensions} from 'lib/enum';
import {ErrorsHandler} from 'lib/errors-handler';

import * as CustomerSubmissionService from 'services/customer-submission-service';

const MAX_WIDTH = 3500;
const MAX_HEIGHT = 3500;

export class Main {
  constructor() {
    this.locale = Locale;
    this.translate = this.locale.translate;
    this.errors = new ErrorsHandler();
    this.language = State.getStorage('me.language', 'vi');
    this.master = {};
    this.mapping = {
      id: 'id',
      text: 'text',
    };
    this.form = {files: [], custom_fields: {}};
    this.requiredFields = {
      fields: ['subject', 'message', 'email', 'name', 'phone'],
      custom_fields: ['reason', 'location'],
    };

    this.maxSize = 3 * 1024 * 1024;
    this.maxImageSize = 10 * 1024 * 1024;
    this.maxFile = 5;
    this.isReset = true;
    this.customFieldsKey = {};
    this.showError = false;
    this.showReport = false;
    this.submissionError = false;
    this.siteKey = Config.get('captcha.siteKey');
    this.loading = {};
    this.disabled = false;
    this.locationSelected = {};
    this.locationEcommerce = {};
  }

  async attached() {
    this.loadActiveLanguage();

    const {error, data} = await CustomerSubmissionService.listCustomFields();

    if (error) {
      return;
    }

    _.map(SubmissionCustomFields, (field) => {
      const value = _.get(data, `${field}.values`);
      this.master[field] = value;
      this.customFieldsKey[field] = _.get(data, `${field}.fieldKey`, '');
    });
  }

  async activate(params) {
    this.params = params;

    if (params.ecom === true || params.ecom === 'true') {
      const {data} = await CustomerSubmissionService.getLocationEcommerce();
      const customFieldValue = _.get(data, 'value', {});
      const customFieldKey = _.get(data, 'fieldKey');

      this.locationSelected = customFieldValue;
      this.locationEcommerce = {[customFieldKey]: customFieldValue.value};
      this.form.custom_fields = {[customFieldKey]: customFieldValue.value};
      this.disabled = true;
    }
  }

  reloadForm() {
    this.form = {files: [], custom_fields: this.locationEcommerce};
    this.isReset = true;
    this.isError = null;
    const boxImages = document.getElementById('box-images');
    _.forEach(boxImages.querySelectorAll('.image-item'), (element) => {
      element.remove();
    });
    this.submissionError = null;
  }

  onChanged(event, field) {
    if (!event.detail) {
      return;
    }

    const value = _.get(event, 'detail.value.origin.value');
    const fieldId = this.customFieldsKey[field];
    this.form.custom_fields[fieldId] = value;
  }

  async submit() {
    this.errors.clearFormErrors('#form-submission');
    this.isReset = false;
    this.existsError = !this.validate(this.form);
    if (this.existsError || this.loading.submit) {
      return;
    }

    const data = this.form;
    const formData = new FormData();
    this.loading.submit = true;

    _.forEach(data, (value, key) => {
      if (key !== 'files') {
        typeof value === 'object'
          ? formData.append(key, JSON.stringify(value))
          : formData.append(key, value || '');
      } else {
        _.forEach(value, (file) => {
          formData.append('attachments', file);
        });
      }
    });

    if (this.captchaHandler) {
      const token = await this.captchaHandler.execute();
      formData.append('captchaToken', token);
    } else {
      this.errors.showServerErrors({
        single: 'form.invalid_captchaToken',
      });
      this.loading.submit = false;

      return;
    }

    const results = await CustomerSubmissionService.addFeedback(formData);

    if (results && results.error) {
      this.submissionError = this.errors.showServerErrors(
        results.error,
        '#form-submission'
      );
      this.showError = true;
      this.loading.submit = false;

      return;
    }

    this.loading.submit = false;

    this.showReport = true;
    this.reloadForm();
  }

  validate(data) {
    let errors = {};
    _.map(this.requiredFields.fields || {}, (field) => {
      const value = _.get(data, `${field}`);
      if (_.isEmpty(value) || _.isEmpty(_.trim(value))) {
        errors[field] = `form.${field}_required`;
      }
    });

    _.map(this.requiredFields.custom_fields || {}, (field) => {
      const fieldKey = this.customFieldsKey[field];
      const value = _.get(data, `custom_fields.${fieldKey}`);

      if (_.isEmpty(value)) {
        errors[field] = `form.custom_fields.${field}_required`;
      }
    });

    if (!_.isEmpty(errors)) {
      this.errors.showClientErrors(errors, '#form-submission');

      return false;
    }

    return true;
  }

  async fileSelected(event) {
    if (_.isEmpty(event.target.files)) {
      return;
    }

    const files = event.target.files;
    if (this.maxFile < files.length + this.form.files.length) {
      this.submissionError = this.locale.message('upload_file.max_file');
      this.showError = true;
      return;
    }

    const filterdFile = await this.filterFiles(files);

    _.each(filterdFile, (file) => {
      this.form.files.push(file);
      this.showFileUpload(file);
    });

    event.target.value = '';
  }

  humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kb', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + ' ' + units[u];
  }

  showFileUpload(file) {
    const boxImages = document.getElementById('box-images');
    let imageItem = document.createElement('div');
    imageItem.classList.add('image-item');

    let imageItemRight = document.createElement('div');
    imageItemRight.classList.add('image-item-right');

    let imageIconDelete = document.createElement('img');

    const removeFile = (fileName) => {
      const newFiles = _.filter(
        this.form.files,
        (files) => files.name !== fileName
      );
      this.form.files = newFiles;
    };

    imageIconDelete.src = '../../../assets/img/trash-can.svg';
    imageIconDelete.alt = 'trash-can';
    imageIconDelete.addEventListener('click', function (e) {
      this.parentElement.parentElement.remove();
      removeFile(file.name);
    });
    const formatFile = this.detectFileType(file.name);
    imageItem.innerHTML = `<div class="image-item">
    <i class="fa ${formatFile.format}"  alt="icon-image" ></i>
              <p class="image-item-name" style="margin-bottom: 0;
                         ">${file.name}</p>
          </div>`;

    imageItemRight.innerHTML = `<span>${this.humanFileSize(
      file.size,
      true
    )}</span>`;
    imageItemRight.appendChild(imageIconDelete);
    imageItem.appendChild(imageItemRight);
    boxImages.appendChild(imageItem);
  }

  closedPopup() {
    this.showError = false;
    this.showReport = false;
    this.submissionError = null;
  }

  recaptchaReady = (handler) => {
    this.captchaHandler = handler;
  };

  detectFileType(file) {
    let file_type = (
      (file || '').replace(/\?.+/, '').split(/\./).pop() || ''
    ).toLowerCase();

    if (['png', 'jpeg', 'jpg', 'gif'].includes(file_type)) {
      return {
        format: 'fa-file-image-o',
        ext: file_type,
      };
    }
    if (['m4a'].includes(file_type)) {
      return {
        format: 'fa-video-camera',
        ext: file_type,
      };
    }
    if (['mp3', 'wav'].includes(file_type)) {
      return {
        format: 'fa-microphone',
        ext: file_type,
      };
    }
    if (['txt'].includes(file_type)) {
      return {
        format: 'fa-file-text-o',
        ext: file_type,
      };
    }
    if (['pdf'].includes(file_type)) {
      return {
        format: 'fa-file-pdf-o',
        ext: file_type,
      };
    }
    if (['doc', 'docx'].includes(file_type)) {
      return {
        format: 'fa-file-word-o',
        ext: file_type,
      };
    }
    if (['xls', 'xlsx'].includes(file_type)) {
      return {
        format: 'fa-file-excel-o',
        ext: file_type,
      };
    }
    if (['ppt', 'pptx'].includes(file_type)) {
      return {
        format: 'fa-file-powerpoint-o',
        ext: file_type,
      };
    }
    if (['tar', 'gz', 'zip', 'rar'].includes(file_type)) {
      return {
        format: 'fa-file-archive-o',
        ext: file_type,
      };
    }
    return {
      format: 'fa-file-o',
      ext: file_type,
    };
  }

  async reloadLanguage(language) {
    if (this.language === language) {
      return;
    }

    this.language = language;
    await this.locale.changeLocale(language);

    location.reload();
  }

  async loadActiveLanguage() {
    const newSpanActive = document.getElementById(`language-${this.language}`);
    newSpanActive.classList.add('active');
  }

  async filterFiles(files) {
    try {
      const result = await Bluebird.map(files || [], async (file) => {
        const fileName = _.get(file, 'name', '');
        const extension = `.${fileName.split('.').pop()}`;

        if (!_.includes(Extensions, extension)) {
          this.handleError('upload_file.type');

          return;
        }

        if (file.size < this.maxSize) {
          return file;
        }

        if (_.includes(ImageExtensions, extension)) {
          if (file.size > this.maxImageSize) {
            this.handleError('upload_file.img_size');

            return;
          }

          const newFile = await this.resizeFile(file);

          return newFile;
        }

        this.handleError('upload_file.size');

        return;
      });

      return _.compact(result);
    } catch (err) {
      this.submissionError = this.errors.showServerErrors(err, '#form-refund');
    }
  }

  resizeFile(file) {
    if (!file) {
      return;
    }

    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const reader = new FileReader();

      reader.onload = function (e) {
        const img = document.createElement('img');

        img.onload = function () {
          let width = img.width;
          let height = img.height;

          if (width > MAX_WIDTH) {
            width = MAX_WIDTH;
            height = Math.round((img.height * MAX_WIDTH) / img.width);
          }
          if (height > MAX_HEIGHT) {
            height = MAX_HEIGHT;
            width = Math.round((img.width * MAX_HEIGHT) / img.height);
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          canvas.toBlob((blob) => {
            const newFile = new File([blob], file.name, {type: blob.type});
            resolve(newFile);
          }, file.type);
        };

        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  }

  handleError(errorMessage) {
    this.submissionError = this.locale.message(errorMessage);
    this.showError = true;
  }
}
