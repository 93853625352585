import {inject, noView, bindable} from 'aurelia-framework';

const recaptchaCallbackName = 'setRecaptchaReady';
const ready = new Promise(
  (resolve) => (window[recaptchaCallbackName] = resolve)
);

// https://developers.google.com/recaptcha/docs/display#explicit_render
let script = document.createElement('script');
script.src = `https://www.google.com/recaptcha/enterprise.js?onload=${recaptchaCallbackName}&render=explicit`;
script.async = true;
script.defer = true;
document.head.appendChild(script);

@noView()
@inject(Element)
export class Recaptcha {
  @bindable ready;
  @bindable siteKey;

  constructor(element) {
    this.element = element;
  }

  attached() {
    ready
      .then(() =>
        window.grecaptcha.enterprise.render(
          this.element,
          {
            sitekey: this.siteKey,
            size: 'invisible',
          },
          this.action
        )
      )
      .then(() => this.ready && this.ready(window.grecaptcha.enterprise));
  }
}
