import {inject, customElement, bindable} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';

import Notifier from 'lib/notifier';
import Locale from 'lib/locale';
import State from 'lib/state';
import {debug} from 'lib/logger';

import * as Util from 'lib/util';
import * as CommonService from 'services/common.v2';

@customElement('mde-tags-event')
@inject(Element, BindingSignaler)
export class MdeTagsEvent {
  //changable
  @bindable diffFlag;
  @bindable dataSelected = [];
  @bindable dataSources = [];
  //one time
  @bindable sourceConfig = {};
  @bindable selectedConfig = {};
  @bindable insertConfig = {};
  @bindable dataMap = {};

  @bindable isDisabled = false;
  @bindable placeholder = '';
  @bindable mdeStyle = '';
  @bindable mdeClass = '';
  constructor(element, signaler) {
    this.element = element;
    this.signaler = signaler;

    let self = this;

    this.mhelper = new Helper();
    this.minput = new Input(signaler);
    this.msource = new Source(this.mhelper);
    this.mselected = new Selected(this.mhelper);

    this.display = {
      get sources() {
        return self.msource.data;
      },
      get selected() {
        return self.mselected.data;
      },
      get input() {
        return self.minput;
      },
      is_showing_options: false,
    };

    this.name =
      this.constructor.name.padEnd(30, '-') +
      ' ' +
      Math.random().toString().substr(-3);
  }

  attached() {
    this.minput.startInterval();
  }

  detached() {
    this.minput.clearInterval();
  }

  bind() {
    this.mhelper.init(
      this.dataMap,
      this.selectedConfig,
      this.sourceConfig,
      this.insertConfig
    );

    this.minput.bindFunctions(
      {
        onFocusChanged: (value) => this.onFocusChanged(value),
        onValueChanged: (value) => this.onValueChanged(value),
        onInsertData: (value) => this.onInsertData(value),
      },
      {
        validateInsertData: (value) => this.validateInsertData(value),
      }
    );

    this.mselected.bindFunctions({
      onValueChanged: () => this.selectedChanged(),
    });

    this.initData();
  }

  diffFlagChanged() {
    this.initData();
  }

  dataSelectedChanged() {
    this.initData('selectedChanged');
  }

  dataSourcesChanged() {
    this.initData();
  }

  initData(name) {
    let dataSources = this.mhelper.clearDuplicated(this.dataSources || []);
    let dataSelected = this.mhelper.clearDuplicated(this.dataSelected || []);

    debug(this.name, 'Init data', dataSelected, dataSources);

    if (name === 'selectedChanged' && !this.mselected.isDiff(dataSelected)) {
      return;
    }

    this.msource.init(dataSelected, dataSources);
    this.mselected.init(dataSelected, dataSources);
  }

  selectedChanged() {
    debug(this.name, 'Event on data changed');
    Util.fireEvent(this.element, this.mselected.getData());
  }

  onSelectedOption(option) {
    debug(this.name, 'Event on selected', option);
    let selected = this.mselected.addSelected(option);
    if (selected) {
      this.msource.filterSources({exclusion: option.value});
    }
    this.minput.setFocus();
  }

  onRemovedSelected(selected) {
    debug(this.name, 'Event on removed', selected);
    let spliced = this.mselected.removeSelected(selected);
    if (spliced) {
      this.msource.filterSources({inclusion: selected.value});
    }
  }

  onFocusChanged(value) {
    let hintable = this.mhelper.config.source.hintable;
    let refreshOnHint = this.mhelper.config.source.refreshOnHint;
    let isDisabled = this.isDisabled;

    let isShowable = value && !isDisabled && hintable;
    this.display.is_showing_options = isShowable;

    if (isShowable && refreshOnHint) {
      this.msource.filterSources();
    }
  }

  onValueChanged(value) {
    this.msource.filterSources({text: value});
  }

  validateInsertData(value) {
    if (!this.insertConfig.enable) {
      return;
    }
    debug(this.name, 'Event on validating', value);
    return this.mhelper.validate(value);
  }

  onInsertData(value) {
    debug(this.name, 'Event on inserting', value);
    let selected = this.mselected.insertSelected(value);
    if (selected) {
      this.msource.filterSources({exclusion: selected.value});
      this.minput.userChangedText('', {name: 'insert'});
    }
  }
}

class Source {
  //contain data
  constructor(helper) {
    this.helper = helper;
    this.data = [];
    this.name = 'source';
  }

  mapConfig() {
    let localConfig = {type: 'local'};
    if (this.helper.config.source.request.url) {
      localConfig = {
        type: 'url',
        method: this.helper.config.source.request.method,
      };
    }
    return localConfig;
  }

  init(exclusions, options) {
    this.config = this.mapConfig();
    this.filter = {
      text: '',
      exclusions: {},
    };
    this.data.splice(0);

    exclusions.forEach((item) => (this.filter.exclusions[item.value] = true));

    if (this.config.type === 'local') {
      this.buildLocalSources(options || []);
    } else if (this.config.type === 'url') {
      this.filterRemoteSources();
    }
  }

  buildLocalSources(sources) {
    this.localSource = sources;
    this.filterLocalSources();
  }

  filterLocalSources() {
    this.mergeData(
      this.localSource.filter((item) => {
        let isFilterText = true;
        if (this.filter.text !== undefined) {
          isFilterText =
            item.text.toLowerCase().includes(this.filter.text) ||
            item.value.toString().toLowerCase().includes(this.filter.text);
        }
        return isFilterText && !this.filter.exclusions[item.value];
      })
    );
  }

  filterRemoteSources(next) {
    this.fetchDataSources(next);
  }

  filterSources(filter = {}) {
    if (filter.exclusion) {
      let index = this.data.findIndex(
        (item) => item.value === filter.exclusion
      );
      if (index !== -1) {
        this.data.splice(index, 1);
      }
      this.filter.exclusions[filter.exclusion] = true;
    }
    if (filter.inclusion) {
      delete this.filter.exclusions[filter.inclusion];
    }
    if (filter.exclusions !== undefined) {
      this.filter.exclusions = filter.exclusions;
    }
    if (filter.text !== undefined) {
      this.filter.text = filter.text.toLowerCase();
    }
    if (this.config.type === 'local') {
      this.filterLocalSources();
    } else if (this.config.type === 'url') {
      this.filterRemoteSources();
    }
  }

  mergeData(rows) {
    this.data.splice(0);
    rows.forEach((item) => {
      this.data.push(item);
    });
    //        for (let i = 0; i < this.data.length; i++) {
    //            Object.assign(this.data[i], rows[i])
    //        }
    //        for (let i = 0; i < rows.length; i++) {
    //            this.data.push(rows[i]);
    //        }
  }

  fetchDataSources(next) {
    let method = this.config.method;
    let url = this.helper.getSourceRequestQuery(this.filter);
    let promise;

    if (method === 'get') {
      promise = CommonService.GET(url);
    } else {
      promise = CommonService.FETCH(url, {
        data: this.helper.getSourceRequestData(this.filter),
        method,
      });
    }

    promise.then(
      (res) => {
        if (res.errors) {
          return next && next(res.errors);
        }
        this.mergeData(
          this.helper.clearDuplicated(
            this.helper.getResponseData(res, this.name)
          )
        );
        next && next();
      },
      (err) => {
        next && next(err);
      }
    );
  }
}

class Selected {
  constructor(helper, service) {
    this.helper = helper;
    this.name = 'selected';
    this.data = [];

    this.events = {
      listeners: {},
      get onValueChanged() {
        return this.listeners.onValueChanged || function () {};
      },
    };
  }

  mapConfig() {
    let localConfig = {type: 'local'};
    if (this.helper.config.selected.request.url) {
      localConfig = {
        type: 'url',
        method: this.helper.config.selected.request.method,
      };
    }
    return localConfig;
  }

  init(selected, sources) {
    this.config = this.mapConfig();
    this.filter = {
      exclusions: {},
    };
    this.data.splice(0);
    this.initDefaultSelected(selected, sources);
  }

  bindFunctions(listeners) {
    Object.assign(this.events.listeners, listeners);
  }

  isDiff(selected) {
    if (selected.length !== this.data.length) {
      return true;
    }
    return selected.some((item) => !this.filter.exclusions[item.value]);
  }

  addSelected(option) {
    let selected = this.select(option);
    if (selected) {
      this.events.onValueChanged();
    }
    return selected;
  }

  removeSelected(option) {
    let removed = this.remove(option);
    if (removed) {
      this.events.onValueChanged();
    }
    return removed;
  }

  insertSelected(value) {
    let selected = this.select(this.helper.getFrom(value));
    if (selected) {
      this.events.onValueChanged();
    }
    return selected;
  }

  getData() {
    return this.data.map((item) => this.helper.rebuildFrom(item));
  }

  initDefaultSelected(selected, sources) {
    (selected || []).forEach((item) => this.select(item));
    if (!this.data.length) {
      return;
    }
    if (this.config.type === 'local') {
      this.loadTextFromSource(sources);
    } else if (this.config.type === 'url') {
      this.fetchSelectedText();
    }
  }

  loadTextFromSource(sources) {
    let marker = {};
    sources.forEach((option) => (marker[option.value] = option.text));
    this.data.forEach((item) => (item.text = marker[item.value] || item.text));
  }

  fetchSelectedText(next) {
    let method = this.config.method;
    let url = this.helper.getSelectedRequestQuery(this.filter);
    let promise;

    if (method === 'get') {
      promise = CommonService.GET(url);
    } else {
      promise = CommonService.FETCH(url, {
        data: this.helper.getSelectedRequestData(this.filter),
        method,
      });
    }

    promise.then(
      (res) => {
        if (res.errors) {
          return next && next(res.errors);
        }
        this.loadTextFromSource(
          this.helper.clearDuplicated(
            this.helper.getResponseData(res, this.name)
          )
        );
        next && next();
      },
      (err) => {
        next && next(err);
      }
    );
  }

  select(option) {
    if (this.filter.exclusions[option.value]) {
      return;
    }
    this.data.push(option);
    this.filter.exclusions[option.value] = true;
    return option;
  }

  remove(option) {
    if (!this.filter.exclusions[option.value]) {
      return;
    }
    delete this.filter.exclusions[option.value];
    let index = this.data.findIndex((item) => item.value === option.value);
    if (index !== -1) {
      return this.data.splice(index, 1)[0];
    }
  }
}

//manage processing data
class Helper {
  constructor() {
    this._p = Symbol('processed');
  }

  mapConfig(mapConfig, selectedConfig, sourceConfig, insertConfig) {
    Object.assign(insertConfig, {
      type: insertConfig.type || null,
      regex: insertConfig.regex || {},
      enable: insertConfig.enable || false,
    });
    Object.assign(selectedConfig, {
      url: selectedConfig.url || '',
      method: selectedConfig.method || '',
      path: selectedConfig.path || '',
      exclusion: selectedConfig.exclusion || {},
    });
    Object.assign(sourceConfig, {
      url: sourceConfig.url || '',
      method: sourceConfig.method || '',
      path: sourceConfig.path || '',
      limit: sourceConfig.limit || {},
      filter: sourceConfig.filter || {},
      exclusion: sourceConfig.exclusion || {},
      hintable:
        sourceConfig.hintable === undefined
          ? !insertConfig.enable
          : sourceConfig.hintable,
    });

    return {
      data: {
        id: mapConfig.id || 'value',
        text: mapConfig.text || 'text',
        sortCol: mapConfig.sortCol || '',
        sortOrder: mapConfig.sortOrder || '',
        explanation: mapConfig.explanation || '',
      },
      insert: {
        type: insertConfig.type,
        enable: insertConfig.enable,
        regex: {
          value: insertConfig.regex.value || '',
          flag: insertConfig.regex.flag || '',
        },
      },
      selected: {
        request: {
          url: selectedConfig.url,
          method: selectedConfig.method.trim().toLowerCase() || 'get',
          exclusion: {
            q: selectedConfig.exclusion.query || '',
            type: selectedConfig.exclusion.type || 'params',
          },
        },
        response: {
          paths: selectedConfig.path ? selectedConfig.path.split('.') : [],
          transform: _.isFunction(selectedConfig.transform)
            ? selectedConfig.transform
            : null,
        },
      },
      source: {
        hintable: sourceConfig.hintable,
        refreshOnHint: sourceConfig.refreshOnHint,
        request: {
          url: sourceConfig.url,
          method: sourceConfig.method.trim().toLowerCase() || 'get',
          limit: {
            q: sourceConfig.limit.query || '',
            total: sourceConfig.limit.total || undefined,
            type: sourceConfig.limit.type || 'params',
          },
          filter: {
            q: sourceConfig.filter.query || '',
            type: sourceConfig.filter.type || 'params',
          },
          exclusion: {
            q: sourceConfig.exclusion.query || '',
            type: sourceConfig.exclusion.type || 'params',
          },
        },
        response: {
          paths: sourceConfig.path ? sourceConfig.path.split('.') : [],
          transform: _.isFunction(selectedConfig.transform)
            ? selectedConfig.transform
            : null,
        },
      },
    };
  }

  init(mapConfig, selectedConfig, sourceConfig, insertConfig) {
    this.config = this.mapConfig(
      mapConfig || {},
      selectedConfig || {},
      sourceConfig || {},
      insertConfig || {}
    );
  }

  getValue(option) {
    if (typeof option !== 'object') {
      return option;
    }
    if (option[this._p]) {
      return option.value;
    }
    return option[this.config.data.id];
  }

  getExplanation(option) {
    if (typeof option !== 'object') {
      return this.config.data.explanation === true ? option : '';
    }
    if (option[this._p]) {
      return option.explanation || '';
    }
    return this.config.data.explanation
      ? option[this.config.data.explanation] || ''
      : '';
  }

  getValues(data) {
    return data.map((item) => this.getFrom(item).value);
  }

  getText(option) {
    if (typeof option !== 'object') {
      return option;
    }
    if (option[this._p]) {
      return option.text;
    }
    return option[this.config.data.text];
  }

  getSortCol(option) {
    if (typeof option !== 'object') {
      return null;
    }
    if (option[this._p]) {
      return option.sortCol;
    }
    return this.config.data.sortCol ? option[this.config.data.sortCol] : null;
  }

  getFrom(option) {
    if (option && typeof option === 'object' && option[this._p]) {
      return option;
    }
    let data = {
      text: this.getText(option),
      value: this.getValue(option),
      sortCol: this.getSortCol(option),
      explanation: this.getExplanation(option),
    };
    data[this._p] = true;
    return data;
  }

  rebuildFrom(option) {
    let data = {};
    data[this.config.data.id] = option.value;
    if (this.config.data.id !== this.config.data.text) {
      data[this.config.data.text] = option.text;
    }
    return data;
  }

  getRequestLimitData() {
    let q = this.config.source.request.limit.q;
    let total = this.config.source.request.limit.total;
    let type = this.config.source.request.limit.type;
    let data = {};

    if (type !== 'data' || !q || total === undefined) {
      return data;
    }
    data[q] = this.config.source.request.limit.total || 0;
    return data;
  }

  getRequestExclusionData(exclusions, name) {
    let q = this.config[name].request.exclusion.q;
    let type = this.config[name].request.exclusion.type;
    let data = {};
    if (type !== 'data' || !q || !exclusions.length) {
      return data;
    }
    data[q] = exclusions;
    return data;
  }

  getRequestFilterData(text) {
    let q = this.config.source.request.filter.q;
    let type = this.config.source.request.filter.type;
    let data = {};
    if (type !== 'data' || !q || !text) {
      return data;
    }
    data[q] = text;
    return data;
  }

  getRequestLimitQuery() {
    let q = this.config.source.request.limit.q;
    let total = this.config.source.request.limit.total;
    let type = this.config.source.request.limit.type;
    if (type !== 'params' || !q || total === undefined) {
      return '';
    }
    return q + '=' + (this.config.source.request.limit.total || 0);
  }

  getRequestExclusionQuery(exclusions, name) {
    let q = this.config[name].request.exclusion.q;
    let type = this.config[name].request.exclusion.type;
    if (type !== 'params' || !q || !exclusions.length) {
      return '';
    }
    return q + '=' + exclusions.join('&' + q + '=');
  }

  getRequestFilterQuery(text) {
    let q = this.config.source.request.filter.q;
    let type = this.config.source.request.filter.type;

    if (type !== 'params' || !q || !text) {
      return '';
    }
    return q + '=' + text;
  }

  getSourceRequestQuery(filter = {}) {
    let name = 'source';
    let url = (this.config[name].request.url || '').trim();
    let op = url.includes('?') ? '&' : '?';
    let limitq = this.getRequestLimitQuery();
    let filterq = this.getRequestFilterQuery(filter.text);
    let exclusionsq = this.getRequestExclusionQuery(
      Object.keys(filter.exclusions || {}),
      name
    );
    if (!url) {
      return '';
    }

    return `${url}${op}${[limitq, filterq, exclusionsq]
      .filter((item) => item)
      .join('&')}`;
  }

  getSourceRequestData(filter = {}) {
    let name = 'source';
    return Object.assign(
      {},
      this.getRequestLimitData(),
      this.getRequestFilterData(filter.text),
      this.getRequestExclusionData(Object.keys(filter.exclusions || {}), name)
    );
  }

  getSelectedRequestQuery(filter = {}) {
    let name = 'selected';
    let url = (this.config[name].request.url || '').trim();
    let op = url.includes('?') ? '&' : '?';
    let exclusionsq = this.getRequestExclusionQuery(
      Object.keys(filter.exclusions || {}),
      name
    );
    if (!url) {
      return '';
    }

    return `${url}${op}${exclusionsq}`;
  }

  getSelectedRequestData(filter = {}) {
    let name = 'selected';
    return Object.assign(
      {},
      this.getRequestExclusionData(Object.keys(filter.exclusions || {}), name)
    );
  }

  validate(value) {
    let regex = null;
    if (this.config.insert.type === 'email') {
      regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    } else if (this.config.insert.regex.value) {
      regex = new RegExp(
        this.config.insert.regex.value,
        this.config.insert.regex.flag
      );
    } else {
      return true;
    }
    return regex.test(value);
  }

  getResponseData(res, name = 'source') {
    let data = res;

    const transform = this.config[name].response.transform;
    if (transform) {
      return transform(res);
    }

    this.config[name].response.paths.forEach((path) => {
      if (data !== undefined) {
        data = data[path];
      }
    });
    return data;
  }

  clearDuplicated(rows) {
    let data = [];
    let marker = {};
    if (!Array.isArray(rows)) {
      return [];
    }
    rows.forEach((row) => {
      let pdata = this.getFrom(row);
      if (marker[pdata.value]) {
        return;
      }
      data.push(pdata);
      marker[pdata.value] = true;
    });
    return data;
  }
}

class Input {
  //manage input
  constructor(signaler) {
    this.signaler = signaler;
    this.element = null;

    this.userLog = {
      _pressing: false,
      is_end: true,
      last_texted: 0,
      text: '',

      set pressing(val) {
        this._pressing = val;
        if (!val) {
          this.is_end = false;
          this.last_texted = Date.now();
        }
      },
      get pressing() {
        return this._pressing;
      },
    };

    this.focusLog = {
      _focusing: false,
      input_focusing: false,
      last_focused: 0,
      set focusing(val) {
        this._focusing = val;
        this.last_focused = Date.now();
      },
      get focusing() {
        return this.input_focusing || this._focusing;
      },
    };

    this.events = {
      listeners: {},
      get onFocusChanged() {
        return this.listeners.onFocusChanged || function () {};
      },
      get onValueChanged() {
        return this.listeners.onValueChanged || function () {};
      },
      get onInsertData() {
        return this.listeners.onInsertData || function () {};
      },
    };

    this.validators = {
      validators: {},
      get validateInsertData() {
        return (
          this.validators.validateInsertData ||
          function () {
            return false;
          }
        );
      },
    };

    this.focusState = false;
    this.last_text_value = '';
  }

  bindFunctions(listeners, validators) {
    Object.assign(this.events.listeners, listeners);
    Object.assign(this.validators.validators, validators);
  }

  setFocus() {
    this.focusLog.input_focusing = true;
  }

  setText(value) {
    this.userLog.text = value;
    this.text = value;
    this.signaler.signal('input-value-updated');
  }

  eventTextChanged(to, from, options) {
    if (options.name === 'enter' && this.validators.validateInsertData(to)) {
      debug('Event tag inserted', to);
      return this.events.onInsertData(to);
    }
    if (from !== to) {
      debug('Event tag text changed', to);
      this.events.onValueChanged(to);
      this.last_text_value = to;
    }
  }

  eventFocusChanged(value) {
    if (this.focusState === value) {
      return;
    }
    this.focusState = value;
    debug('Event tag focus changed', value);
    this.events.onFocusChanged(value);
  }

  userChangedFocus(value) {
    if (value === this.focusState) {
      return;
    }
    if (!value && this.userLog.text) {
      // lost focus act the same as enter
      this.userChangedText(null, {name: 'enter'});
    }
    this.eventFocusChanged(value);
  }

  focusedIntervalJob() {
    if (!this.focusState || this.focusLog.focusing) {
      return;
    }
    if (Date.now() - this.focusLog.last_focused > 200) {
      this.userChangedFocus(false);
    }
  }

  userChangedText(text, options = {}) {
    let from = this.last_text_value;
    let to = text === null ? this.userLog.text : text;

    this.userLog.is_end = true;
    if (text !== null) {
      this.setText(to);
    }
    this.eventTextChanged(to, from, options);
  }

  textedIntervalJob() {
    if (this.userLog.is_end || this.userLog.pressing) {
      return;
    }
    if (Date.now() - this.userLog.last_texted > 300) {
      this.userChangedText(null, {name: 'interval'});
    }
  }

  startInterval() {
    this.interval100ms = setInterval(() => {
      this.focusedIntervalJob();
      this.textedIntervalJob();
    }, 100);
  }
  clearInterval() {
    clearInterval(this.interval100ms);
  }

  onFocusChanged() {
    let val = document.activeElement === this.element;
    if (val) {
      this.userChangedFocus(true);
    }
    this.focusLog.focusing = val;
  }

  onClearText() {
    if (this.text || this.userLog.text) {
      this.userChangedText('', {name: 'button'});
    }
    this.setFocus();
  }

  onKeyUp(event) {
    this.userLog.text = event.target.value;
    this.userLog.pressing = false;
    let keycode = event.which || event.keyCode;
    if (keycode === 27) {
      //escape
      return this.userChangedText('', {name: 'escape'});
    } else if (keycode === 13) {
      //enter
      return this.userChangedText(null, {name: 'enter'});
    }
  }

  onKeyDown(event) {
    this.userLog.pressing = true;
    return true;
  }

  onChanged(event) {
    event.stopImmediatePropagation();
  }
}
