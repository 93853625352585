'use strict';
import {inject} from 'aurelia-framework';
import Locales from './locale';
import Notifier from './notifier';
import _ from 'lodash';

@inject()
export class ErrorsHandler {
  constructor() {
    this.locales = Locales;
  }

  showServerErrors(message, options) {
    if (message.single) {
      Notifier.error(message.single);
      return;
    }

    const single = _.split(message, ':')[1];
    if (single) {
      return this.locales.message(single);
    }

    if (options) {
      this.showClientErrors(message, options);
      return;
    }
  }

  showClientErrors(errors, selector) {
    if (!selector) {
      const single = _.split(errors, ':')[1];
      return this.locales.message(single);
    }

    if (!document.querySelector(selector)) {
      return;
    }

    this.clearFormErrors(selector);
    var form = document.querySelector(selector),
      errors = errors || {};

    _.forEach(form.querySelectorAll(' div[id]'), (input) => {
      const error = errors[input.name] || errors[input.id];
      // create a span html tag

      if (error) {
        let imageIconDelete = document.createElement('img');
        imageIconDelete.src = '../../../assets/img/error.svg';
        imageIconDelete.alt = 'icon-error';

        const span = document.createElement('span');
        span.id = (input.name || input.id) + '-error';
        span.innerHTML = this.locales.message(error);
        span.classList.add('error');

        const newDiv = document.createElement('div');

        newDiv.appendChild(imageIconDelete);
        newDiv.appendChild(span);
        newDiv.classList.add('error-group', 'validate-msg');
        this.insertAfter(input, newDiv);
      }
    });
  }

  resetFormGroup(formGroup) {
    formGroup.classList.remove('error-group');

    _.forEach(formGroup.querySelectorAll('.error'), (el) => {
      var parent = el.parentNode;
      parent.parentNode.removeChild(parent);
    });
  }

  clearFormErrors(selector) {
    const form = document.querySelector(selector),
      that = this;
    _.forEach(form.querySelectorAll('.error-group'), (element) => {
      that.resetFormGroup(element);
    });
  }

  insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
}
