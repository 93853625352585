import {customElement, inject, bindable, bindingMode} from 'aurelia-framework';

@customElement('mde-popup')
@inject(Element)
export class MdePopup {
  // init variables
  @bindable mdeId = 'mde-modal-controller';
  @bindable mdeTitle = '';
  @bindable({
    defaultBindingMode: bindingMode.twoWay,
  })
  mdeShow = false;
  @bindable mdeStyle = '';
  @bindable mdeClass = '';
  @bindable mdeContainerClass = '';

  constructor(element) {
    this.element = element;
  }

  attached() {
    // this.closePopup();
  }
}
