import _ from 'lodash';
import CryptoJS from 'crypto-js';

import Config from './config';
import {debug} from './logger';

const md5Hostname = CryptoJS.MD5(window.location.hostname).toString();
const md5HostKey = md5Hostname.substring(0, 8) + md5Hostname.substring(-8);
const shouldEncrypt = Config.get('encrypt', false);

const toKey = (key) => {
  return shouldEncrypt
    ? md5HostKey + '.' + CryptoJS.MD5(_.toPath(key).join('.'))
    : [window.location.hostname, ..._.toPath(key)].join('.');
};

const state = {};

export const get = (...params) => {
  const [key, df = null] = params;

  const value = _.get(state, key, df);

  if (value === undefined) {
    debug('[state][get]', params, value);
  }

  return value;
};

export const set = (key, value) => {
  debug('[state][set]', key);

  return _.set(state, key, value);
};

export const getStorage = (...params) => {
  const [key, df = null] = params;

  const data = localStorage.getItem(toKey(key));
  if (_.isEmpty(data)) {
    debug('[state][get]', params, df);

    return df;
  }

  try {
    const value = JSON.parse(data).data || df;
    debug('[state][get]', params, value);

    return value;
  } catch (err) {
    debug('[state][get]', params, df);

    return df;
  }
};

export const setStorage = (key, value) => {
  debug('[state][setStorage]', key);

  return localStorage.setItem(
    toKey(key),
    JSON.stringify({
      data: value,
    })
  );
};

export default {
  get,
  set,
  getStorage,
  setStorage,
};
