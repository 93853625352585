import {GET, POST, PUT, DELETE, FETCH} from './common.v2';
import _ from 'lodash';

const responseData = (response) => {
  try {
    const {data, error, success} = response;

    return {
      data: _.get(data, 'data'),
      error: _.get(error, 'errors', error),
      success,
    };
  } catch (err) {
    return {
      error: err,
      success: false,
    };
  }
};

export const addFeedback = async (data) => {
  const response = await FETCH(`/feedback/submit`, {data});

  return responseData(response);
};

export const listCustomFields = async () => {
  const response = await GET(`/custom-field/submission`);

  return responseData(response);
};

export const getLocationEcommerce = async () => {
  const response = await GET(`/custom-field/submission/location/ecommerce`);

  return responseData(response);
};
