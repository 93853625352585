import {HttpClient} from 'aurelia-fetch-client';

import Config from 'lib/config';

import _ from 'lodash';

const httpClient = new HttpClient();

const handleError = async (ex) => {
  try {
    const result = ex && ex.json ? await ex.json() : ex;
    return {
      success: false,
      error: _.get(result, 'error', result),
    };
  } catch (err) {
    console.warn('error while handler exception', err);
    return {
      success: false,
      error: ex,
    };
  }
};

const parseURI = (uri, options) => {
  const baseUrl =
    _.trimEnd(_.get(options, 'baseUrl', Config.get('api')), '/') + '/';

  return {
    url: _.trimStart(uri, '/'),
    baseUrl: baseUrl,
  };
};

export const GET = async (uri, options) => {
  const {url, baseUrl} = parseURI(uri, options);

  const params = _.get(options, 'params', {});
  const headers = _.get(options, 'headers', {});

  const http = httpClient.configure((httpConfig) => {
    httpConfig.useStandardConfiguration().withBaseUrl(baseUrl);
  });

  const query = _.isEmpty(params)
    ? ''
    : '?' + new URLSearchParams(params || {}).toString();

  try {
    const response = await http.fetch(url + query, {
      method: 'get',
      headers,
    });
    const data = await response.json();

    return data;
  } catch (ex) {
    return await handleError(ex);
  }
};

export const POST = async (uri, options) => {
  const {url, baseUrl} = parseURI(uri, options);

  const params = _.get(options, 'params', {});
  const headers = _.get(options, 'headers', {});
  const data = _.get(options, 'data', {});
  const body = _.isEmpty(data) ? '' : JSON.stringify(data);
  const http = httpClient.configure((httpConfig) => {
    httpConfig.useStandardConfiguration().withBaseUrl(baseUrl);
  });

  const query = _.isEmpty(params)
    ? ''
    : '?' + new URLSearchParams(params || {}).toString();

  try {
    const response = await http.fetch(url + query, {
      method: 'post',
      headers,
      body,
    });
    const data = await response.json();

    return data;
  } catch (ex) {
    return await handleError(ex);
  }
};

export const PUT = async (uri, options) => {
  const {url, baseUrl} = parseURI(uri, options);

  const params = _.get(options, 'params', {});
  const headers = _.get(options, 'headers', {});
  const data = _.get(options, 'data', {});
  const body = _.isEmpty(data) ? '' : JSON.stringify(data);

  const http = httpClient.configure((httpConfig) => {
    httpConfig.useStandardConfiguration().withBaseUrl(baseUrl);
  });

  const query = _.isEmpty(params)
    ? ''
    : '?' + new URLSearchParams(params || {}).toString();

  try {
    const response = await http.fetch(url + query, {
      method: 'put',
      headers,
      body,
    });
    const data = await response.json();

    return data;
  } catch (ex) {
    return await handleError(ex);
  }
};

export const DELETE = async (uri, options) => {
  const {url, baseUrl} = parseURI(uri, options);

  const params = _.get(options, 'params', {});
  const headers = _.get(options, 'headers', {});
  const data = _.get(options, 'data', {});
  const body = _.isEmpty(data) ? '' : JSON.stringify(data);

  const http = httpClient.configure((httpConfig) => {
    httpConfig.useStandardConfiguration().withBaseUrl(baseUrl);
  });

  const query = _.isEmpty(params)
    ? ''
    : '?' + new URLSearchParams(params || {}).toString();

  try {
    const response = await http.fetch(url + query, {
      method: 'delete',
      headers,
      body,
    });
    const data = await response.json();

    return data;
  } catch (ex) {
    return await handleError(ex);
  }
};

export const FETCH = async (uri, options) => {
  const {url, baseUrl} = parseURI(uri, options);

  const params = _.get(options, 'params', {});
  const headers = _.get(options, 'headers', {});
  const method = _.get(options, 'method', 'POST');
  const data = _.get(options, 'data', {});
  const http = httpClient.configure((httpConfig) => {
    httpConfig.useStandardConfiguration().withBaseUrl(baseUrl);
  });

  const query = _.isEmpty(params)
    ? ''
    : '?' + new URLSearchParams(params || {}).toString();

  const body =
    data.constructor.name === 'FormData' ? data : jsonToFormData(data);

  try {
    const response = await http.fetch(url + query, {
      method,
      headers,
      body,
    });
    const data = await response.json();

    return data;
  } catch (ex) {
    return await handleError(ex);
  }
};

const jsonToFormData = (data) => {
  const formData = new FormData();

  _.forEach(data, (n, key) => {
    if (!_.isUndefined(n)) {
      formData.append(key, n);
    }
  });

  return formData;
};
