import _ from 'lodash';
import {
  inject,
  observable,
  customElement,
  bindable,
  bindingMode,
} from 'aurelia-framework';

import * as CommonService from 'services/common.v2';

@customElement('select-language')
export class SelectLanguage {
  @bindable id = `select-language-${
    Date.now() + (Math.random() * Date.now()).toFixed(0)
  }`;

  @bindable({defaultBindingMode: bindingMode.twoWay}) language = 'vi';
  @bindable mdeStyle = 'width: 100%';
  selectedLanguage = 'vi';

  constructor() {}

  languageChanged() {
    if (this.selectedLanguage !== this.language) {
      this.selectedLanguage = this.language;
    }
  }

  async attached() {
    await this.init();
  }

  async init() {
    const languages = await CommonService.GET('language');

    this.languages = languages.map((language) => ({
      _id: language._id,
      name: language.text,
    }));

    this.selectedLanguage = this.language;
  }

  select(event) {
    const value = _.get(event, 'detail.value._id');

    if (value !== this.language) {
      this.language = value;
    }
  }
}
