import {inject, customElement, bindable} from 'aurelia-framework';
import State from 'lib/state';

@customElement('mde-background')
@inject(Element)
export class MdeBackground {
  @bindable urlLogo = '';

  constructor(element) {
    this.element = element;
    this.language = State.getStorage('me.language', 'vi');
  }
}
