import _ from 'lodash';

import {debug} from './logger';

import Config from '../config';

const isDebug = Config.env !== 'prod';

export const get = (...params) => {
  const [key, df] = params;

  const paths = [Config.env, ...(Array.isArray(key) ? key : _.toPath(key))];

  const value = _.get(Config, paths, _.get(Config, key, df));

  debug('[config][get]', params, value);

  return value;
};

export default {
  ...Config,
  get,
  isDebug,
};
