import _ from 'lodash';
import {EventAggregator as AureliaEventAggregator} from 'aurelia-event-aggregator';

import {Events} from './enum';
import Locale from './locale';

export const event = new AureliaEventAggregator();

export function success(message, options) {
  const localized = _.get(options, 'localized', true);
  const timeout = _.get(options, 'timeout');
  const messageParams = _.get(options, 'params');

  const translated = localized
    ? Locale.message(message, messageParams)
    : message;

  const payload = {
    message: translated,
    type: 'success',
    timeout,
  };

  event.publish(Events.showMessage, payload);
}

export function error(message, options) {
  const localized = _.get(options, 'localized', true);
  const timeout = _.get(options, 'timeout');
  const messageParams = _.get(options, 'params');

  const translated = localized
    ? Locale.message(message, messageParams)
    : message;

  const payload = {
    message: translated,
    type: 'error',
    timeout,
  };

  event.publish(Events.showMessage, payload);
}

export function show(message, options) {
  const localized = _.get(options, 'localized', true);
  const timeout = _.get(options, 'timeout');
  const type = _.get(options, 'type', 'info');
  const messageParams = _.get(options, 'params');

  const translated = localized
    ? Locale.message(message, messageParams)
    : message;

  const payload = {
    message: translated,
    type,
    timeout,
  };

  event.publish(Events.showMessage, payload);
}

export default {
  event,
  success,
  error,
};
