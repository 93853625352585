import _ from 'lodash';

export class ToHtmlValueConverter {
  toView(value) {
    if (!value) {
      return '';
    }
    return value.replace(/\n/g, '<br>');
  }
}
